import React, { Fragment } from 'react';
import { api, submitForm, openModal, exportData, importData, checkDateFormat, generateBarcode, generateQrCode } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            DataKategori: [],
            q: "",
            DataImport: [],
            FieldImport: [
                { cap: "Kode", sort: "Kode", type: "str" },
                { cap: "No. Panggil", sort: "NoPanggil", type: "str" },
                { cap: "Judul", sort: "Judul", type: "str" },
                { cap: "Penulis", sort: "Penulis", type: "str" },
                { cap: "Penerbit", sort: "Penerbit", type: "str" },
                { cap: "Jenis", sort: "Jenis", type: "str" },
                { cap: "Rak / Link", sort: "Rak", type: "str" },
                { cap: "Halaman", sort: "Halaman", type: "str" },
                { cap: "Jumlah Buku", sort: "Qty", type: "str" },
                { cap: "Kategori", sort: "Kategori", type: "str" },
                { cap: "LokasiRak", sort: "LokasiRak", type: "str" },
                { cap: "ISBN", sort: "ISBN", type: "str" },
                { cap: "Deskripsi", sort: "Deskripsi", type: "str" },
                { cap: "Sinopsis", sort: "Sinopsis", type: "str" },
                { cap: "Status", sort: "Status", type: "str" }
            ],
            JumlahCetak: [
                { QRCode: "", Barcode: "" }
            ]
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/perpus_api", { act: "data master buku", q: this.state.q }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/perpus_api", { act: "detail master buku", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: Detail, DataKategori: sql.kategori });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handleImEx(ACT, Type) {
        if (ACT == "Export") {
            let data = [], title = "";
            if (Type == "contoh") {
                data = [{
                    "Kode": "MIPA2",
                    "Judul": "MUDIK",
                    "Penulis": "heru",
                    "Penerbit": "nayla",
                    "Tahun": "2024",
                    "Jenis": "Fisik",
                    "Rak": "asdasd",
                    "Halaman": 20,
                    "Qty": 10,
                    "KategoriID": "1",
                    "Status": "Baik",
                    "ISBN": "1234567890",
                    "LokasiRak": "Rak 1",
                    "Deskripsi": "Buku ini tentang mudik",
                    "Sinopsis": "Buku ini tentang mudik",
                    "NoPanggil": "MIPA2",
                }];
                title = "Contoh Format Import Buku";
            } else {
                let sql = await api("controler/perpus_api", { act: "data master buku", q: "" }, true);
                if (sql.status == "sukses") data = sql.data;
                title = "Data Buku";
            }
            exportData(data, title, this.state.FieldImport);
        } else {
            openModal("modalImport");
        }
    }

    async handleImEx(ACT, Type) {
        this.setState({ DataImport: [] });
        if (ACT == "Export") {
            let data = [], title = "";
            if (Type == "contoh") {
                data = [{
                    "Kode": "MIPA2",
                    "Judul": "MUDIK",
                    "Penulis": "heru",
                    "Penerbit": "nayla",
                    "Tahun": "2024",
                    "Jenis": "Fisik",
                    "Rak": "asdasd",
                    "Halaman": 20,
                    "Qty": 10,
                    "KategoriID": "1",
                    "Status": "Baik",
                    "ISBN": "1234567890",
                    "LokasiRak": "Rak 1",
                    "Deskripsi": "Buku ini tentang mudik",
                    "Sinopsis": "Buku ini tentang mudik",
                    "NoPanggil": "MIPA2",
                }];
                title = "Contoh Format Import Buku";
            } else {
                let sql = await api("controler/perpus_api", { act: "data master buku", q: "" }, true);
                if (sql.status == "sukses") data = sql.data;
                title = "Data Buku";
            }
            exportData(data, title, this.state.FieldImport);
        } else {
            openModal("modalImport");
        }
    }

    async handleLoadExcel(e) {
        let Data = await importData(e.target.files[0]);
        this.setState({ DataImport: Data });
    }

    async handleProsesImport(e) {
        e.target.disabled = true;
        let Data = this.state.DataImport;
        let ct = 0;
        for (let dd of Data) {
            let sql = await api("model/perpus_crud", {
                act: "tambah master buku",
                Kode: dd.Kode,
                Judul: dd.Judul,
                Penulis: dd.Penulis,
                Penerbit: dd.Penerbit,
                Jenis: dd.Jenis,
                Rak: dd.Rak,
                Halaman: dd.Halaman || 1,
                Tahun: dd.Tahun || 2024,
                Qty: dd.Qty || 1,
                KategoriID: 0,
                Kategori: dd.Kategori,
                ISBN: dd.ISBN,
                LokasiRak: dd.LokasiRak,
                Deskripsi: dd.Deskripsi,
                Sinopsis: dd.Sinopsis,
                NoPanggil: dd.NoPanggil || "",
                Status: dd.Status
            }, true);
            Data[ct].IsSukses = sql.status == "sukses" ? "text-light bg-success" : "text-light bg-danger";
            this.setState({ DataImport: Data });
            ct++;
        }
        e.target.disabled = false;
    }

    handleModalCetakCode(Detail) {
        this.setState({ Detail: Detail, JumlahCetak: [{ QRCode: Detail.Kode, Barcode: Detail.Kode }] });
        openModal("modalDetail");
    }

    handleCetak() {
        let myWindow = window.open('', '', 'width=1000,height=800');
        let dom = document.getElementById('divQrCodeBarcode').innerHTML;
        dom += `<link href="./bs/css/bootstrap.min.css" rel="stylesheet" />`;
        myWindow.document.write(dom);
        myWindow.document.close();
        myWindow.focus();
        setTimeout(() => {
            myWindow.print();
        }, 1000);
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-8 main-title">Master Buku</div>
                            <div className="col-md-4 d-flex gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="input-group">
                                    <button className='btn btn-sm btn-default' onClick={() => this.handleImEx('Import')}><i className="fas fa-upload"></i></button>
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Buku' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-print", fn: (e) => this.handleModalCetakCode(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master buku" : "edit master buku"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Buku" : "Edit Buku"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kode</label>
                                                <input type="text" className='form-control' id="edtKode" name="Kode" value={this.state.Detail.Kode} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Kode = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Kode</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>No. Panggil</label>
                                                <input type="text" className='form-control' id="NoPanggil" name="NoPanggil" value={this.state.Detail.NoPanggil} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NoPanggil = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan No. Panggil</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Judul</label>
                                                <input type="text" className='form-control' id="edtJudul" name="Judul" value={this.state.Detail.Judul} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Judul = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Judul</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Penulis</label>
                                                <input type="text" className='form-control' name="Penulis" value={this.state.Detail.Penulis} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Penulis = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Penulis</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Penerbit</label>
                                                <input type="text" className='form-control' name="Penerbit" value={this.state.Detail.Penerbit} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Penerbit = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Penerbit</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tahun</label>
                                                <input type="year" className='form-control' name="Tahun" value={this.state.Detail.Tahun} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tahun = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tahun</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Jumlah Halaman</label>
                                                <input type="number" className='form-control' name="Halaman" value={this.state.Detail.Halaman} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Halaman = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Halaman</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Jenis</label>
                                                <select className='form-select' name="Jenis" value={this.state.Detail.Jenis} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Jenis = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="Fisik">Buku Fisik</option>
                                                    <option value="Digital">Digital</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan tentukan jenis</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>ISBN</label>
                                                <input type="text" className='form-control' name="ISBN" value={this.state.Detail.ISBN} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ISBN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan ISBN</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Jumlah Buku</label>
                                                <input type="number" className='form-control' name="Qty" value={this.state.Detail.Qty} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Qty = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Jumlah</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Klasifikasi</label>
                                                <select className='form-select' name="KategoriID" value={this.state.Detail.KategoriID} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.KategoriID = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    {
                                                        this.state.DataKategori.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan tentukan jenis</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>{this.state.Detail.Jenis == "Fisik" ? "Rak" : "Link"}</label>
                                                <input type="text" className='form-control' name="Rak" value={this.state.Detail.Rak} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Rak = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan {this.state.Detail.Jenis == "Fisik" ? "Rak" : "Link"}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Lokasi Rak</label>
                                                <input type="text" className='form-control' name="LokasiRak" value={this.state.Detail.LokasiRak} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.LokasiRak = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Lokasi Rak</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Deskripsi</label>
                                        <textarea className='form-control' name="Deskripsi" value={this.state.Detail.Deskripsi} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Deskripsi = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Deskripsi</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Sinopsis</label>
                                        <textarea className='form-control' name="Sinopsis" value={this.state.Detail.Sinopsis} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Sinopsis = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Sinopsis</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.Detail.Status} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required >
                                            <option value="Baik">Baik</option>
                                            <option value="Rusak">Rusak</option>
                                            <option value="Hilang">Hilang</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan tentukan status</div>
                                    </div>

                                    <div className="form-check form-switch">
                                        <input className="form-check-input" name='IsTahunan' type="checkbox" role="switch" id="chkTahunan" checked={this.state.Detail.IsTahunan == 1 ? true : false} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.IsTahunan = e.target.checked == true ? 1 : 0;
                                            this.setState({ Detail: data });
                                        }} />
                                        <label className="form-check-label" htmlFor="chkTahunan">Buku Tahunan</label>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center gap-2'>
                                        {
                                            this.state.Detail.Kode != "" || this.state.Detail.Kode != undefined ? generateBarcode(this.state.Detail.Kode) : ""
                                        }
                                        {
                                            this.state.Detail.Kode != "" || this.state.Detail.Kode != undefined ? generateQrCode(this.state.Detail.Kode) : ""
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="modalDetail" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cetak Barcode dan QRCode</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="number" className="form-control" value="1" onChange={(e) => {
                                    let data = [];
                                    for (let i = 0; i < e.target.value; i++) {
                                        data.push({ QRCode: this.state.Detail.Kode, Barcode: this.state.Detail.Kode });
                                    }
                                    this.setState({ JumlahCetak: data });
                                }} />
                                <p></p>
                                <div id='divQrCodeBarcode'>
                                    <div className='row'>
                                        {
                                            this.state.JumlahCetak.map((dd, i) => {
                                                return <div className='col-4 mb-2' key={i}>
                                                    {generateBarcode(dd.Barcode)}
                                                    <p></p>
                                                    {generateQrCode(dd.QRCode)}
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" onClick={() => this.handleCetak()}><i className='fas fa-print'></i> Cetak</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalImport" tabIndex="-1" aria-labelledby="modalImport" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Import Data</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm">
                                    <input type="file" className="form-control form-control-sm" id="edtFileExcel" accept=".xls, .xlsx" onChange={(e) => this.handleLoadExcel(e)} />
                                    <button type='button' className='btn btn-sm btn-default' onClick={(e) => this.handleImEx("Export", 'contoh')}>Contoh Format</button>
                                </div>
                                <p></p>
                                <div className="table-responsive" style={{ height: "700px", maxHeight: "700px", overflowY: "auto" }}>
                                    <table className="table table-stripped table-sm">
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.FieldImport.map((th, i) => {
                                                        return <th key={i}>{th.cap}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataImport.map((rr, i) => {
                                                    return <tr key={i} className={rr.IsSukses}>
                                                        {
                                                            this.state.FieldImport.map((dd, ii) => {
                                                                return <td key={ii}>{rr[dd.sort]}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-default" onClick={(e) => this.handleProsesImport(e, "mapel")}><i className='fas fa-save'></i> Proses</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master buku" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Buku</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus buku ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
