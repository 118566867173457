import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, host } from '../Modul';
import RendTables from '../component/RendTable';
import { Details } from 'devextreme-react/cjs/file-manager';
let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataRusak: [],
            FieldRusak: [],
            DataService: [],
            FieldService: [],
            Detail: { Status: "" },
            DetailSarana: [],
            D1: saiki("01"),
            D2: saiki(),
            DataSarana: [],
            Status: "Rusak",
            q: "",
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/sarpras_api", { act: "data sarana rusak", q: this.state.q, Status: this.state.Status }, true);
        if (sql.status == "sukses") {
            if (this.state.Status == "Rusak") {
                let Field = [
                    { cap: "", sort: "", type: "opsi" },
                    { cap: "Tanggal", sort: "Tanggal", type: "date" },
                    { cap: "Sarana", sort: "NamaSarana", type: "str" },
                    { cap: "Qty", sort: "Qty", type: "number" },
                    { cap: "Notes", sort: "Notes", type: "str" },
                    { cap: "Dibuat Oleh", sort: "UserName", type: "str" }
                ];
                this.setState({ DataRusak: sql.data, FieldRusak: Field });
            } else if (this.state.Status == "Diservice") {
                let Field = [
                    { cap: "", sort: "", type: "opsi" },
                    { cap: "Tanggal Service", sort: "TanggalService", type: "date" },
                    { cap: "Sarana", sort: "NamaSarana", type: "str" },
                    { cap: "Qty", sort: "Qty", type: "number" },
                    { cap: "Diservice Oleh", sort: "DiServiceOleh", type: "str" },
                    { cap: "DiService Ke", sort: "TempatService", type: "str" },
                ];
                this.setState({ DataService: sql.data, FieldService: Field });
            } else if (this.state.Status == "Sudah Diservice") {
                let Field = [
                    { cap: "", sort: "", type: "opsi" },
                    { cap: "Tanggal Selesai", sort: "TanggalSelesaiService", type: "date" },
                    { cap: "Sarana", sort: "NamaSarana", type: "str" },
                    { cap: "Qty", sort: "Qty", type: "number" },
                    { cap: "Diservice Oleh", sort: "DiServiceOleh", type: "str" },
                    { cap: "Biaya", sort: "BiayaService", type: "number" },
                    { cap: "DiService Ke", sort: "TempatService", type: "str" },
                ];
                this.setState({ Data: sql.data, Field: Field });
            }
        }
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/sarpras_api", { act: "detail peminjaman sarana", DocNumber: Detail.DocNumber }, true);
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DetailSarana: sql.detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    modalCariSarana() {
        openModal("modalSarana");
        this.handleCariSarana("");
    }

    async handleCariSarana(val = "") {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let sql = await api("controler/sarpras_api", { act: "data master sarana", q: val });
            if (sql.status == "sukses") this.setState({ DataSarana: sql.data });
        }, 500);
    }

    modalCariPeminjam() {
        openModal("modalPeminjam");
        this.handleCariPeminjam("");
    }

    async handleCariPeminjam(val) {
        let JenisUser = this.state.JenisUser;
        let sql = await api("controler/sarpras_api", { act: "data peminjam", JenisUser: JenisUser, q: val }, true);
        if (sql.status == "sukses") this.setState({ DataPeminjam: sql.data });
    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
    };

    async handleModalEdiStatus(data = { Status: "" }) {
        this.setState({ Detail: data });
        openModal("modalEditStatus");
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Sarana Rusak</div>
                            <div className="col-md-3 d-flex align-items-end gap-1">
                                <div className="input-group input-group-sm">
                                    <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => {
                                    this.setState({ Status: "Rusak" });
                                    setTimeout(() => {
                                        this.handleMain();
                                    }, 500);
                                }}>Rusak</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => {
                                    this.setState({ Status: "Diservice" });
                                    setTimeout(() => {
                                        this.handleMain();
                                    }, 500);
                                }}>Diservice</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => {
                                    this.setState({ Status: "Sudah Diservice" });
                                    setTimeout(() => {
                                        this.handleMain();
                                    }, 500);
                                }}>Sudah Diservice</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <RendTables
                                    tbody={this.state.DataRusak}
                                    thead={this.state.FieldRusak}
                                    group={[{ cap: "Tanggal", sort: "Tanggal" }]}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalEdiStatus(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                <RendTables
                                    tbody={this.state.DataService}
                                    thead={this.state.FieldService}
                                    group={[{ cap: "Tanggal", sort: "Tanggal" }]}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalEdiStatus(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                <RendTables
                                    tbody={this.state.Data}
                                    thead={this.state.Field}
                                    group={[{ cap: "Tanggal", sort: "Tanggal" }]} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah sarana rusak" : "edit sarana rusak"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Sarana Rusak" : "Edit Sarana Rusak"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Tanggal</label>
                                        <input type="date" className="form-control" name='Tanggal' id="edtTanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Tanggal = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className="invalid-feedback">Silahkan pilih tanggal</div>
                                    </div>
                                    <p></p>
                                    <button className='btn btn-default' type='button' onClick={(e) => this.modalCariSarana()} >Tambah Sarana</button>
                                    <p></p>
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Kode</th>
                                                    <th>Nama</th>
                                                    <th>Qty</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DetailSarana.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <button className='btn btn-danger' type='button' onClick={(e) => {
                                                                    let data = this.state.DetailSarana;
                                                                    let temp = [];
                                                                    for (let dd in data) if (dd != i) temp.push(data[dd]);
                                                                    this.setState({ DetailSarana: temp });
                                                                }}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type='hidden' name="IDSarana[]" value={tr.IDSarana} />
                                                                <input type="text" name="Kode[]" className='form-control form-control-sm' value={tr.Kode} readOnly />
                                                            </td>
                                                            <td><input type="text" name="NamaSarana[]" className='form-control form-control-sm' value={tr.NamaSarana} readOnly /></td>
                                                            <td>
                                                                <input type="number" name="Qty[]" className='form-control form-control-sm' value={tr.Qty} onChange={(e) => {
                                                                    let data = this.state.DetailSarana;
                                                                    data[i].Qty = e.target.value;
                                                                    this.setState({ DetailSarana: data });
                                                                }} required />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="Notes[]" className='form-control form-control-sm' value={tr.Notes} onChange={(e) => {
                                                                    let data = this.state.DetailSarana;
                                                                    data[i].Notes = e.target.value;
                                                                    this.setState({ DetailSarana: data });
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalForm'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalSarana" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Master Sarana</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="search" className="form-control" onChange={(e) => this.handleCariSarana(e.target.value)} />
                                <p></p>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>Kode</th>
                                                <th>Nama</th>
                                                <th>Jumlah</th>
                                                <th>Keterangan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataSarana.map((tr, i) => {
                                                    return (<tr key={i} className='cursor' onClick={(e) => {
                                                        let data = this.state.DetailSarana;
                                                        data.push({ Kode: tr.Kode, NamaSarana: tr.Nama, Qty: 1, IDSarana: tr.ID, Notes: "" });
                                                        this.setState({ DetailSarana: data });
                                                        document.getElementById('btnTutupModalSarana').click();
                                                    }}>
                                                        <td>{tr.Kode}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.Qty}</td>
                                                        <td>{tr.Keterangan}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalSarana'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalEditStatus" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="ubah status sarpras" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Ubah Status</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select className="form-select" name='Status' value={this.state.Detail.Status || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.value;
                                            this.setState({ Details: data });
                                        }} id="edtStatus" required>
                                            <option value="">Silahkan pilih tindakan</option>
                                            <option value="Diservice">Diservice</option>
                                            <option value="Sudah Diservice">Sudah Diservice</option>
                                            <option value="Baik">Baik</option>
                                        </select>
                                        <div className="invalid-feedback">Silahkan pilih status</div>
                                    </div>
                                    {
                                        this.state.Detail.Status == "Diservice" ?
                                            <Fragment>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Tanggal Service</label>
                                                            <input type='date' className="form-control" name='TanggalService' value={this.state.Detail.TanggalService || saiki()} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.TanggalService = e.target.value;
                                                                this.setState({ Details: data });
                                                            }} required />
                                                            <div className="invalid-feedback">Silahkan pilih status</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Di Service Oleh</label>
                                                            <input type='text' className="form-control" name='DiServiceOleh' value={this.state.Detail.DiServiceOleh || ""} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.DiServiceOleh = e.target.value;
                                                                this.setState({ Details: data });
                                                            }} required />
                                                            <div className="invalid-feedback">Silahkan masukan orang yang service</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label>Service Ke</label>
                                                    <input type='text' className="form-control" name='TempatService' value={this.state.Detail.TempatService || ""} onChange={(e) => {
                                                        let data = this.state.Detail;
                                                        data.TempatService = e.target.value;
                                                        this.setState({ Details: data });
                                                    }} required />
                                                    <div className="invalid-feedback">Silahkan masukan service dimana</div>
                                                </div>
                                            </Fragment>
                                            : this.state.Detail.Status == "Sudah Diservice" ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Tanggal Selesai Service</label>
                                                            <input type='date' className="form-control" name='TanggalSelesaiService' value={this.state.Detail.TanggalSelesaiService || saiki()} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.TanggalSelesaiService = e.target.value;
                                                                this.setState({ Details: data });
                                                            }} required />
                                                            <div className="invalid-feedback">Silahkan pilih status</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Biaya Service</label>
                                                            <input type='number' className="form-control" name='BiayaService' value={this.state.Detail.BiayaService || ""} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.BiayaService = e.target.value;
                                                                this.setState({ Details: data });
                                                            }} required />
                                                            <div className="invalid-feedback">Silahkan masukan biaya service</div>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalEditStatus'>Batal</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus sarpras rusak" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Laporan Kerusakan</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Main;
