import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, tanggalIndo } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            DataTahunAjaran: [],
            q: "",
            Akses: true
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/ppdb_api", { act: "data PPDB", q: this.state.q }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/ppdb_api", { act: "detail PPDB", ID: Detail.ID }, true);
        this.setState({ Detail: sql.data || Detail, DataTahunAjaran: sql.tahunajaran });
        openModal("modalFormMenu");
    }

    modalDelete(Detail, act = "hapus tahun ajaran") {
        this.setState({ ID: Detail.ID, DeleteAct: act });
        openModal("modalDelete")
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ? <Fragment>
                            <div className="row">
                                <div className="col-md-8 main-title">Master PPDB</div>
                                <div className="col-md-4 d-flex gap-1">
                                    <div className="input-group">
                                        <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                        <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                        <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            <RendTables
                                tbody={this.state.Data}
                                thead={this.state.Field}
                                group={[
                                    { cap: "Tahun Ajaran", sort: "TahunAjaran" }
                                ]}
                                opt={[
                                    { icon: "fas fa-calendar-week", fn: (e) => this.handleSemester(e) },
                                    { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                    { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                ]} />
                        </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah PPDB" : "edit PPDB"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah PPDB" : "Edit PPDB"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Gelombang</label>
                                                <input type="text" className='form-control' name="Gelombang" id="edtGelombang" value={this.state.Detail.Gelombang || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Gelombang = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Gelombang</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tahun Ajaran</label>
                                                <select className='form-select' name="IDTahunAjaran" id="Tahun" value={this.state.Detail.IDTahunAjaran || "0"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDTahunAjaran = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option>Silahkan pilih tahun ajaran</option>
                                                    {
                                                        this.state.DataTahunAjaran.map((opt, i) => {
                                                            return <option value={opt.ID}>{opt.Nama}</option>
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan Tahun</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Status</label>
                                                <select className='form-select' name="Status" value={this.state.Detail.Status || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Status = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="Draft">Draft</option>
                                                    <option value="Aktif">Aktif</option>
                                                    <option value="Finish">Selesai</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan status</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Keterangan</label>
                                                <input type="text" className='form-control' name="Keterangan" id="Keterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Keterangan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Keterangan</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Mulai</label>
                                                <input type="date" className='form-control' name="TanggalMulai" id="edtTanggalMulai" value={this.state.Detail.TanggalMulai || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalMulai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Mulai</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Selesai</label>
                                                <input type="date" className='form-control' name="TanggalSelesai" id="edtTanggalSelesai" value={this.state.Detail.TanggalSelesai || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalSelesai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Selesai</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kuota Pendaftaran</label>
                                                <input type="number" className='form-control' name="Kuota" id="edtKuota" value={this.state.Detail.Kuota || "100"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Kuota = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan kuota penerimaan</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Harga Formulir</label>
                                                <input type="number" className='form-control' name="HargaFormulir" id="edtHargaFormulir" value={this.state.Detail.HargaFormulir || "0"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.HargaFormulir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan harga formulir</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value="hapus PPDB" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus PPDB</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>Apakah anda yakin akan menghapus data ini !!</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
