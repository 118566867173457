import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, exportData, importData, openFile, host } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataImport: [],
            FieldImport: [
                { cap: "Kode", sort: "Kode" },
                { cap: "Nama", sort: "Nama" },
                { cap: "Harga", sort: "Harga" },
                { cap: "Qty", sort: "Qty" },
                { cap: "TanggalBeli", sort: "TanggalBeli" },
                { cap: "Keterangan", sort: "Keterangan" },
            ]
        };
    }

    async componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("controler/sarpras_api", { act: "data master sarana", q: this.state.q }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/sarpras_api", { act: "detail master sarana", ID: Detail.ID });
        console.log(sql.data);
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handleImEx(ACT, Type) {
        if (ACT == "Export") {
            let data = [], title = "";
            if (Type == "contoh") {
                data = [{
                    "Kode": "NB",
                    "Nama": "Notebook",
                    "Harga": 0,
                    "Qty": 0,
                    "TanggalBeli": "2024-11-02",
                    "Keterangan": "Lenovo"
                }];
                title = "Contoh Format Import Sarana";
            } else {
                let sql = await api("controler/sarpras_api", { act: "data master sarana", q: "" }, true);
                if (sql.status == "sukses") data = sql.data;
                title = "Data Sarana";
            }
            exportData(data, title, this.state.FieldImport);
        } else {
            openModal("modalImport");
        }
    }

    async handleLoadExcel(e) {
        let Data = await importData(e.target.files[0]);
        this.setState({ DataImport: Data });
    }

    async handleProsesImport(e) {
        e.target.disabled = true;
        let Data = this.state.DataImport;
        let ct = 0;
        for (let dd of Data) {
            let sql = await api("model/sarpras_crud", {
                act: "tambah master sarana",
                "Kode": dd.Kode,
                "Nama": dd.Nama,
                "Harga": dd.Harga,
                "Qty": dd.Qty,
                "TanggalBeli": dd.TanggalBeli,
                "Keterangan": dd.Keterangan
            }, true);
            Data[ct].IsSukses = sql.status == "sukses" ? "text-light bg-success" : "text-light bg-danger";
            this.setState({ DataImport: Data });
            ct++;
        }
        e.target.disabled = false;
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Master Sarpras</div>
                            <div className="col-md-3 d-flex justify-content-end align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="input-group input-group-sm">
                                    <button className='btn btn-sm btn-default' onClick={() => this.handleImEx('Import')}><i className="fas fa-upload"></i></button>
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama / Kode' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah master sarana" : "edit master sarana"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Master Sarpras" : "Edit Master Sarpras"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Tanggal Beli</label>
                                        <input type="date" className="form-control" id="TanggalBeli" name="TanggalBeli" value={this.state.Detail.TanggalBeli || saiki()} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.TanggalBeli = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Tanggal Beli</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kode</label>
                                                <input type="text" className="form-control" id="edtKode" name="Kode" value={this.state.Detail.Kode || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Kode = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Kode</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className="form-control" id="edtNama" name="Nama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Nama = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Harga</label>
                                                <input type="number" className="form-control" id="edtHarga" name="Harga" value={this.state.Detail.Harga || 0} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Harga = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Harga</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Qty</label>
                                                <input type="number" className="form-control" id="edtQty" name="Qty" value={this.state.Detail.Qty || 1} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Qty = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Qty</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >

                <div className="modal fade" id="modalImport" tabIndex="-1" aria-labelledby="modalImport" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Import Data</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm">
                                    <input type="file" className="form-control form-control-sm" id="edtFileExcel" accept=".xls, .xlsx" onChange={(e) => this.handleLoadExcel(e)} />
                                    <button type='button' className='btn btn-sm btn-default' onClick={(e) => this.handleImEx("Export", 'contoh')}>Contoh Format</button>
                                </div>
                                <p></p>
                                <div className="table-responsive" style={{ height: "700px", maxHeight: "700px", overflowY: "auto" }}>
                                    <table className="table table-stripped table-sm">
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.FieldImport.map((th, i) => {
                                                        return <th key={i}>{th.cap}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataImport.map((rr, i) => {
                                                    return <tr key={i} className={rr.IsSukses}>
                                                        {
                                                            this.state.FieldImport.map((dd, ii) => {
                                                                return <td key={ii}>{rr[dd.sort]}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-default" onClick={(e) => this.handleProsesImport(e, "mapel")}><i className='fas fa-save'></i> Proses</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master sarana" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Master Sarpras</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Main;
