import React, { Fragment } from 'react';
import { api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            DataGroup: [],
            Detail: {},
            q: "",
            Akses: true
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/mastermapel_api", { act: "data master keagamaan", q: this.state.q }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Akses: true });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/mastermapel_api", { act: "detail master mapel", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-8 main-title">Master Mata Pelajaran</div>
                                    <div className="col-md-4 d-flex gap-1">
                                        <div className="input-group">
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                            <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Admin' />
                                            <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <RendTables tbody={this.state.Data}
                                    thead={[
                                        { cap: "", sort: "", type: "opsi" },
                                        { cap: "Kode", sort: "Kode" },
                                        { cap: "Nama", sort: "Nama" },
                                        { cap: "Keterangan", sort: "Keterangan" },
                                    ]}

                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermapel_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type="hidden" name="Jenis" value="Keagamaan" />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master mapel" : "edit master mapel"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Master Kegiatan Keagamaan" : "Edit Master Kegiatan Keagamaan"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Kode</label>
                                        <input type="text" className='form-control' id="edtKodePelajaran" name="Kode" value={this.state.Detail.Kode || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Kode = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan kode</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' id="edtNamaPelajaran" name="Nama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Nama = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan nama</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <input type="text" className='form-control' id="edtKeteranganPelajaran" name="Keterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan keterangan</div>
                                    </div>

                                    <div className="form-check form-switch">
                                        <input className="form-check-input" name='IsWajib' type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.Detail.IsWajib == 1 ? true : false} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.IsWajib = e.target.checked == true ? 1 : 0;
                                            this.setState({ Detail: data });
                                        }} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Wajib</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermapel_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master mapel" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Mata Pelajaran</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus Mapel ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
