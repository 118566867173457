import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, tanggalIndo, getRentangDate, getJamSaatIni } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataKelas: [],
            DataGuru: [],
            DataMapel: [],
            DataJam: [],
            DataTahun: [],
            D1: saiki(),
            D2: saiki(),
            DataTanggal: [],
            DataJadwal: [],
            ColorTheme: localStorage.getItem("ColorTheme") || "light"
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/kurikulum_api", { act: "data jadwal keagamaan", q: this.state.q, D1: this.state.D1, D2: this.state.D2 }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/kurikulum_api", { act: "detail jadwal pelajaran", ID: Detail.ID, Jenis: "Keagamaan" });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DataJam: sql.jam, DataGuru: sql.guru, DataKelas: sql.kelas, DataMapel: sql.mapel, DataTahun: sql.tahun });
        openModal("modalFormMenu");
    }

    async handleModalFormBatch() {
        let sql = await api("controler/kurikulum_api", { act: "detail jadwal pelajaran", ID: 0, Jenis: "Keagamaan" });
        if (sql.status == "sukses") {
            this.setState({ Detail: {}, DataGuru: sql.guru, DataKelas: sql.kelas, DataMapel: sql.mapel, DataTahun: sql.tahun })
            openModal("modalFormMenuBatch");
        }
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    HandleGetTanggal() {
        let D1 = this.state.D1;
        let D2 = this.state.D2;
        let Tanggal = getRentangDate(D1, D2);
        let DataJadwal = this.state.DataJadwal;
        if (DataJadwal.length == 0) {
            for (let tt of Tanggal) {
                DataJadwal.push({ Tanggal: tt, Kelas: "", JamMulai: getJamSaatIni(), JamSelesai: getJamSaatIni(), Pelajaran: "", IDPelajaran: "", Kelas: "", IDKelas: "" });
            }
        }
        this.setState({ DataJadwal: DataJadwal, DataTanggal: Tanggal });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-6 main-title">Jadwal Kegiatan Keagamaan</div>
                            <div className="col-md-6 d-flex justify-content-end align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalFormBatch()}>Tambah Batch</button>
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Kelas' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ sort: "Tanggal", cap: "Tanggal" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah jadwal keagamaan" : "edit jadwal keagamaan"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Jadwal Keagamaan" : "Edit Jadwal Keagamaan"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type="date" className='form-control' name='Tanggal' value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan pilih tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Jam Mulai</label>
                                                <input type="time" className='form-control' id="edtJamMulai" name="JamMulai" value={this.state.Detail.JamMulai || "07:00"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JamMulai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan pilih Jam Mulai</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Jam Selesai</label>
                                                <input type="time" className='form-control' id="edtJamSelesai" name="JamSelesai" value={this.state.Detail.JamSelesai || "09:00"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JamSelesai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan pilih Jam Selesai</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Guru</label>
                                                <select className='form-select' id="IDGuru" name="IDGuru" value={this.state.Detail.IDGuru || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDGuru = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataGuru) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaGuru = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih Guru</option>
                                                    {
                                                        this.state.DataGuru.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih guru</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kelas</label>
                                                <select className='form-select' id="edtIDKelas" name="IDKelas" value={this.state.Detail.IDKelas || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDKelas = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih kelas</option>
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih kelas</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tahun Ajaran</label>
                                                <select className='form-select' id="edtIDTahunAjaran" name="IDTahunAjaran" value={this.state.Detail.IDTahunAjaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDTahunAjaran = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    {
                                                        this.state.DataTahun.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih tahun ajaran</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kegiatan Keagamaan</label>
                                                <select className='form-select' id="edtIDPelajaran" name="IDPelajaran" value={this.state.Detail.IDPelajaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDPelajaran = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataMapel) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaPelajaran = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih Kegiatan Keagamaan</option>
                                                    {
                                                        this.state.DataMapel.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih mapel</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenuBatch" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value="tambah jadwal keagamaan batch" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Tambah Jadwal Keagamaan Batch</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Awal</label>
                                                <input type="date" className='form-control' value={this.state.D1} onChange={(e) => {
                                                    this.setState({ D1: e.target.value });
                                                    setTimeout(() => {
                                                        this.HandleGetTanggal();
                                                    }, 500);
                                                }} />
                                                <div className='invalid-feedback'>Silahkan pilih tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Akhir</label>
                                                <input type="date" className='form-control' value={this.state.D2} onChange={(e) => {
                                                    this.setState({ D2: e.target.value });
                                                    setTimeout(() => {
                                                        this.HandleGetTanggal();
                                                    }, 500);
                                                }} />
                                                <div className='invalid-feedback'>Silahkan pilih tanggal</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Tahun Ajaran</label>
                                                <select className='form-select' id="edtIDTahunAjaran" name="IDTahunAjaran" value={this.state.Detail.IDTahunAjaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDTahunAjaran = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    {
                                                        this.state.DataTahun.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih tahun ajaran</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Kegiatan Keagamaan</label>
                                                <select className='form-select' id="edtIDPelajaran" name="IDPelajaran" value={this.state.Detail.IDPelajaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDPelajaran = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataMapel) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaPelajaran = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih Kegiatan Agama</option>
                                                    {
                                                        this.state.DataMapel.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih mapel</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Guru</label>
                                                <select className='form-select' id="IDGuru" name="IDGuru" value={this.state.Detail.IDGuru || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDGuru = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataGuru) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaGuru = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih Guru</option>
                                                    {
                                                        this.state.DataGuru.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih guru</div>
                                            </div>
                                        </div>
                                    </div>

                                    <p></p>
                                    {
                                        this.state.DataTanggal.map((tgl, i) => {
                                            return <Fragment>
                                                <div className={this.state.ColorTheme == "dark" ? 'card text-bg-dark' : 'card'}>
                                                    <div className='card-header'>
                                                        <div className='d-flex justify-content-between'>
                                                            <h6>{tanggalIndo(tgl)}</h6>
                                                            <button className='btn btn-default btn-sm' type='button' onClick={() => {
                                                                let DataJadwal = this.state.DataJadwal;
                                                                DataJadwal.push({ Tanggal: tgl, Kelas: "", JamMulai: getJamSaatIni(), JamSelesai: getJamSaatIni(), Pelajaran: "", IDPelajaran: "", Kelas: "", IDKelas: "" });
                                                                this.setState({ DataJadwal });
                                                            }}>Tambah Kelas</button>
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='table-responsive'>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Kelas</th>
                                                                        <th>Jam Mulai</th>
                                                                        <th>Jam Selesai</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.DataJadwal.map((tr, ii) => {
                                                                            if (tr.Tanggal == tgl) {
                                                                                return <tr key={ii}>
                                                                                    <td>
                                                                                        <input type='hidden' name='Tanggal[]' value={tgl} />
                                                                                        <select className='form-select form-select-sm' name='IDKelas[]' value={tr.IDKelas} onChange={(e) => {
                                                                                            let DataJadwal = this.state.DataJadwal;
                                                                                            DataJadwal[ii].IDKelas = e.target.value;
                                                                                            this.setState({ DataJadwal });
                                                                                        }} required>
                                                                                            <option value="">Silahkan pilih kelas</option>
                                                                                            {
                                                                                                this.state.DataKelas.map((opt, kls) => {
                                                                                                    return <option key={kls} value={opt.ID}>{opt.Nama}</option>
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='time' className='form-control form-control-sm' name='JamMulai[]' value={tr.JamMulai} onChange={(e) => {
                                                                                            let DataJadwal = this.state.DataJadwal;
                                                                                            DataJadwal[ii].JamMulai = e.target.value;
                                                                                            this.setState({ DataJadwal });
                                                                                        }} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='time' className='form-control form-control-sm' name='JamSelesai[]' value={tr.JamSelesai} onChange={(e) => {
                                                                                            let DataJadwal = this.state.DataJadwal;
                                                                                            DataJadwal[ii].JamSelesai = e.target.value;
                                                                                            this.setState({ DataJadwal });
                                                                                        }} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <button type='button' className='btn btn-sm btn-danger' onClick={() => {
                                                                                            let DataJadwal = this.state.DataJadwal;
                                                                                            let Temp = [];
                                                                                            for (let dd in DataJadwal) if (dd != ii) Temp.push(DataJadwal[dd]);
                                                                                            this.setState({ DataJadwal: Temp });
                                                                                        }}>
                                                                                            <i className='fas fa-trash'></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p></p>
                                            </Fragment>
                                        })
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus jadwal keagamaan" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Jadwal Keagamaan</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Main;
