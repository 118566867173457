import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, numberFormat, pesan } from '../Modul';
import RendTables from '../component/RendTable';
let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataTahunAjaran: [],
            DataKelas: [],
            Detail: {},
            DataAkun: [],
            DataCard: [],
            DataArAp: [],
            CardID: "",
            Akses: true,
            DataLks: [],
            ExpandGrid: false,
            QCard: ""
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/tu_api", { act: "data daftar ulang all", CardID: this.state.CardID }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalTerbitkanSPP() {
        let sql = await api("controler/tu_api", { act: "data untuk terbitkan daftar ulang" });
        if (sql.status == "sukses") {
            this.setState({ DataTahunAjaran: sql.tahun, DataKelas: sql.kelas, DataSemester: sql.semester });
            openModal("modalTerbitkanSPP");
        }
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handleDetailSpp(e) {
        let data = e.data;
        if (data.ID == undefined) return;
        let sql = await api("controler/tu_api", { act: "detail daftar ulang murid", ID: data.ID }, true);
        if (sql.status == "sukses") {
            this.setState({ DataLks: sql.data });
            openModal("modalDetailSPP");
        }
    }

    async handleModalPembayaran() {
        this.setState({ Detail: {} });
        openModal("modalPembayaran");
    }

    modalCard() {
        this.handleCariCard();
        openModal("modalCard");
    }

    async handleCariCard() {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let q = this.state.QCard;
            let sql = await api("controler/tu_api", { act: "data card", q: q, CardType: "Murid" });
            if (sql.status == "sukses") this.setState({ DataCard: sql.data });
        }, 500);
    }

    handlePilihMurid(e) {
        let data = this.state.Detail;
        data.CardName = e.data.Nama;
        data.CardID = e.data.ID;
        data.CardType = this.state.CardType;
        this.setState({ Detail: data });
        document.getElementById('btnTutupModalCariCard').click();
    }

    async handleModalArAp() {
        if (this.state.Detail.CardID) {
            let sql = await api("controler/tu_api", { act: "data arap", CardID: this.state.Detail.CardID, CardType: "Murid", TransType: "Pembayaran Daftar Ulang" }, true);
            if (sql.status == "sukses") {
                this.setState({ DataArAp: sql.data });
                openModal("modalArAp");
            }
        } else {
            pesan("peringatan", "Silahkan pilih murid terlebih dahulu", "warning");
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-6 main-title">Pembayaran Daftar Ulang</div>
                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleModalPembayaran()}>Tambah Pembayaran</button>
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleModalTerbitkanSPP()}>Terbitkan Tagihan Daftar Ulang</button>
                                            <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Murid' />
                                            <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name='IsWajib' type="checkbox" role="switch" id="chkExpand" checked={this.state.ExpandGrid} onChange={(e) => this.setState({ ExpandGrid: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="chkExpand">Expand Tabel</label>
                                        </div>
                                    </div>
                                </div>
                                <RendTables
                                    tbody={this.state.Data}
                                    thead={this.state.Field}
                                    group={[
                                        { cap: "Kelas", sort: "Kelas" }
                                    ]}
                                    expandGroup={this.state.ExpandGrid}
                                    event={{ click: (e) => this.handleDetailSpp(e) }}
                                />
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                <div className="modal fade" id="modalTerbitkanSPP" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/tu_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value="terbitkan daftar ulang" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Terbitkan Tagihan Daftar Ulang</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Tahun Ajaran</label>
                                        <select className='form-select' id="edtTahunAjaran" name="IDTahunAjaran" value={this.state.Detail.TahunAjaran || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.TahunAjaran = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required >
                                            <option value="">Silahkan Pilih Tahun Ajaran</option>
                                            {
                                                this.state.DataTahunAjaran.map((opt, i) => {
                                                    return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan NIP</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kelas</label>
                                                <select className='form-select' id="Kelas" name="IDKelas" value={this.state.Detail.Kelas || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Kelas = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="">Silahkan Pilih Kelas</option>

                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.Level} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih kelas</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nominal</label>
                                                <input type="number" className='form-control' name="Amount" value={this.state.Detail.Amount || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Amount = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan nominal</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' name="Notes" value={this.state.Detail.Notes || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Notes = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan nominal</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalPembayaran" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/tu_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value="tambah pembayaran" />
                                <input type='hidden' name="CardType" value="Murid" />
                                <input type='hidden' name="TransType" value="Pembayaran Daftar Ulang" />
                                <input type='hidden' name="CardID" value={this.state.Detail.CardID || "0"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Pembayaran</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type='date' className='form-control' name='DocDate' value={this.state.Detail.DocDate || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.DocDate = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIP</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Siswa</label>
                                                <div className='input-group'>
                                                    <input type="text" className='form-control' id="edtCardName" name="CardName" value={this.state.Detail.CardName || ""} onFocus={() => this.modalCard()} readOnly />
                                                    <button type="button" className="btn btn-default" onClick={() => this.modalCard()} id="btnCariCard">
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan pilih kelas</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>LKS Semester</label>
                                                <div className='input-group'>
                                                    <input type="text" className='form-control' id="edtReffDocNumber" name="ReffDocNumber" value={this.state.Detail.ReffDocNumber || ""} onFocus={() => this.handleModalArAp()} readOnly />
                                                    <button type="button" className="btn btn-default" onClick={() => this.handleModalArAp()} id="btnCariReffDocNumber">
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan pilih Penerima / Pembayar</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nominal</label>
                                                <input type="number" className='form-control' name="Amount" value={this.state.Detail.Amount || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Amount = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan nominal</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' name="Notes" value={this.state.Detail.Notes || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Notes = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan nominal</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalArAp" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Pilih Tunggakan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>Tahun Ajaran</th>
                                                <th>Jumlah</th>
                                                <th>Dibayar</th>
                                                <th>Sisa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataArAp.length > 0 ?
                                                    this.state.DataArAp.map((tr, i) => {
                                                        return (<tr key={i} className='cursor' onClick={() => {
                                                            if (tr.Balance > 0) {
                                                                let data = this.state.Detail;
                                                                data.ReffDocNumber = tr.DocNumber;
                                                                data.Amount = tr.Balance;
                                                                this.setState({ Details: data });
                                                                document.getElementById('btnTutupModalCariSPP').click();
                                                            } else {
                                                                pesan("Lunas", "Spp Sudah Lunas", "danger");
                                                            }
                                                        }}>
                                                            <td>{tr.TahunAjaran}</td>
                                                            <td>{numberFormat(tr.Amount)}</td>
                                                            <td>{numberFormat(tr.Amount - tr.Balance)}</td>
                                                            <td>{numberFormat(tr.Balance)}</td>
                                                        </tr>)
                                                    })
                                                    : <tr>
                                                        <td colSpan={5}>Tidak ada tunggakan spp</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariSPP'>Batal</button>
                                <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCard" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Murid</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type='search' className='form-control' placeholder='cari nama' value={this.state.QCard} onChange={(e) => {
                                    this.setState({ QCard: e.target.value });
                                    this.handleCariCard();
                                }} />
                                <p></p>
                                <RendTables
                                    tbody={this.state.DataCard}
                                    event={{ click: (e) => this.handlePilihMurid(e) }}
                                    thead={[
                                        { cap: "NIK", sort: "NIK", type: "str" },
                                        { cap: "Nama", sort: "Nama", type: "str" },
                                        { cap: "Kelas", sort: "Kelas", type: "str" },
                                        { cap: "No Absen", sort: "NoAbsen", type: "str" },
                                        { cap: "Alamat", sort: "Alamat", type: "str" },
                                        { cap: "Telp", sort: "Telp", type: "str" },
                                    ]}
                                    group={[
                                        { cap: "Kelas", sort: "Kelas" }
                                    ]}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariCard'>Batal</button>
                                <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetailSPP" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Tagihan Daftar Ulang</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables
                                    tbody={this.state.DataLks}
                                    thead={[
                                        { cap: "Tahun Ajaran", sort: "TahunAjaran", type: "str" },
                                        { cap: "Nominal", sort: "Amount", type: "number" },
                                        { cap: "Dibayar", sort: "Sisa", type: "number" },
                                        { cap: "Sisa", sort: "Balance", type: "number" },
                                    ]}

                                    group={[
                                        { sort: "TahunAjaran", cap: "Tahun Ajaran" }
                                    ]}

                                    summary={[
                                        { sort: "Amount", type: "sum", cap: "Jumlah" },
                                        { sort: "Sisa", type: "sum", cap: "Dibayar" },
                                        { sort: "Balance", type: "sum", cap: "Sisa" },
                                    ]}

                                    sumgroup={[
                                        { sort: "Amount", type: "sum", cap: "Jumlah" },
                                        { sort: "Sisa", type: "sum", cap: "Dibayar" },
                                        { sort: "Balance", type: "sum", cap: "Sisa" },
                                    ]}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalDetailSpp'>tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
