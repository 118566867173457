import React, { Fragment } from 'react';
import { api, submitForm, openModal, checkDateFormat, saiki, numberFormat } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            DataTahunAjaran: [],
            DataMuridDaftarUlang: [],
            ArrMurid: [],
            IDTahunAjaran: 0,
            DataPPDB: [],
            q: "",
            Akses: true,
            CTBaru: 0,
            CTBeli: 0,
            CTIsi: 0,
            CTDaftar: 0,
            CTDiterima: 0,
            Pwd: "",
            Opt: [
                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
            ],
            Title: "Data Calon Siswa Daftar Online",
            Mode: sessionStorage.getItem("Mode") ? sessionStorage.getItem("Mode") : "Baru"
        };
    }

    async componentDidMount() {
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }

        let sql = await api("controler/kurikulum_api", { act: "data tahun ajaran" });
        if (sql.status == "sukses") {
            let IDTahunAjaran = 0;
            for (let dd of sql.data) {
                if (dd.Status == "Aktif") IDTahunAjaran = dd.ID;
            }
            this.setState({ DataTahunAjaran: sql.data, IDTahunAjaran: IDTahunAjaran });
            setTimeout(() => {
                this.handleMain();
            }, 500);
        }
    }

    handleChangeMode(Mode) {
        sessionStorage.setItem("Mode", Mode);
        if (Mode == "Baru") {
            let Opt = [
                { icon: "fas fa-edit", fn: (e) => this.modalFormEditSiswa(e), color: "text-warning", cap: "Edit" },
                { icon: "fas fa-print", fn: (e) => this.handleModalBeliFormulir(e), cap: "Beli Formulir" },
                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e), color: "text-danger", cap: "Hapus" }
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "Status", sort: "Status", type: "str" },
            ]
            this.setState({ Title: "Data Calon Siswa Daftar Online", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Beli Formulir") {
            let Opt = [
                { icon: "fas fa-window-close", fn: (e) => this.handleBatal(e), color: "text-warning", cap: "Batal" },
                { icon: "fas fa-print", fn: (e) => this.handleCetak(e), cap: "Cetak Formulir" },
                { icon: "fas fa-info-circle", fn: (e) => this.handleModalInfo(e), color: "text-light", cap: "Info" }
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
                { cap: "Password", sort: "PwdHint", type: "str" },
            ]
            this.setState({ Title: "Data Calon Siswa Pembeli Formulir", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Sudah Isi Formulir") {
            let Opt = [
                { icon: "fas fa-edit", fn: (e) => this.modalFormEdit(e), color: "text-warning", cap: "Edit" },
                { icon: "fas fa-print", fn: (e) => this.handleCetak(e), cap: "Cetak Formulir" },
                { icon: "fas fa-cash-register", fn: (e) => this.handleModalDaftarUlang(e), cap: "Daftar Ulang" },
                { icon: "fas fa-window-close", fn: (e) => this.handleBatal(e), color: "text-warning", cap: "Batal" },
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
            ]
            this.setState({ Title: "Data Siswa Sudah Isi Formulir", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Daftar Ulang") {
            let Opt = [
                { icon: "fas fa-edit", fn: (e) => this.modalFormEdit(e), color: "text-warning", cap: "Edit" },
                { icon: "fas fa-cash-register", fn: (e) => this.handleModalTerima(e), cap: "Terima" },
                { icon: "fas fa-window-close", fn: (e) => this.handleBatal(e), color: "text-warning", cap: "Batal" },
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
            ]
            this.setState({ Title: "Data Siswa Sudah Daftar Ulang", Opt: Opt, Field: Field, Mode: Mode });
        } else if (Mode == "Pendaftaran Diterima") {
            let Opt = [
                { icon: "fas fa-edit", fn: (e) => this.modalFormEdit(e), color: "text-warning", cap: "Edit" },
                { icon: "fas fa-window-close", fn: (e) => this.handleBatal(e), color: "text-warning", cap: "Batal" }
            ];
            let Field = [
                { cap: "Opsi", sort: "", type: "opsi" },
                { cap: "Nama", sort: "NAMA", type: "str" },
                { cap: "Email", sort: "EMAIL", type: "str" },
                { cap: "No. Hp", sort: "NO_HANDPHONE", type: "str" },
            ]
            this.setState({ Title: "Data Calon Siswa Diterima", Opt: Opt, Field: Field, Mode: Mode });
        }
        setTimeout(() => {
            this.handleGetData();
        }, 500);
    }

    async handleMain() {
        let sql = await api("controler/ppdb_api", { act: "summary calon siswa", IDTahunAjaran: this.state.IDTahunAjaran, Status: this.state.Mode });
        if (sql.status == "sukses") {
            this.setState({
                CTBaru: sql.baru,
                CTBeli: sql.beli,
                CTIsi: sql.isi,
                CTDaftar: sql.daftar,
                CTDiterima: sql.terima
            });
            this.handleChangeMode(this.state.Mode);
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleGetData() {
        let sql = await api("controler/ppdb_api", { act: "data ppdb", IDTahunAjaran: this.state.IDTahunAjaran, Status: this.state.Mode });
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    async handleModalBeliFormulir(Detail = {}) {
        Detail.Pwd = this.randomCharacter();
        this.setState({ Detail: Detail });
        openModal("modalBeliFormulir");
    }

    randomCharacter() {
        const karakter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let hasil = '';

        for (let i = 0; i < 6; i++) {
            hasil += karakter.charAt(Math.floor(Math.random() * karakter.length));
        }

        return hasil;
    }

    handleSuccessBeliFormulir(data) {
        if (data.status == "sukses") this.handleCetak();
    }

    handleCetak(data = this.state.Detail) {
        let myWindow = window.open('', '', 'width=1000,height=800');
        let Logo = require("../assets/img/logo.png");
        let dom = ` 
            <link href="./bs/css/bootstrap.min.css" rel="stylesheet" />
            <div class="container">
                <div class='d-flex justify-content-between align-items-center'>
                    <img src='${Logo}' style={{ width: "50px", height: "50px" }} />
                    <h4>Formulir Pendaftaran Siswa Baru</h4>
                </div>
                <hr style="border:1px solid black; width:100%">
                <h5 style='text-align:center'>Berikut user name dan password untuk login :</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <td>Username</td>
                            <td>:</td>
                            <td>${data.EMAIL}</td>
                        </tr>
                        <tr>
                            <td>Password</td>
                            <td>:</td>
                            <td>${data.PwdHint}</td>
                        </tr>
                    </tbody>
                </table>
            </div>`;
        myWindow.document.write(dom);
        myWindow.document.close();
        myWindow.focus();
        setTimeout(() => {
            myWindow.print();
        }, 1000);
        this.handleMain();
    }

    modalDelete(Detail, act = "hapus tahun ajaran") {
        this.setState({ ID: Detail.ID, DeleteAct: act });
        openModal("modalDelete")
    }

    async modalFormEditSiswa(data) {
        let sql = await api("controler/ppdb_api", { act: "detail calon murid", ID: data.ID }, true);
        if (sql.status == "sukses") {
            this.setState({ Detail: sql.data ? sql.data : { NAMA: "", EMAIL: "", NO_HANDPHONE: "", ALAMAT: "", IDTahunAjaran: "", IDPPDB: "" }, DataPPDB: sql.ppdb });
            openModal("modalFormEditSiswa");
        }
    }

    async getDataPPDB(ID) {
        let sql = await api("controler/ppdb_api", { act: "data ppdb tahun ajaran", ID: ID });
        if (sql.status == "sukses") this.setState({ DataPPDB: sql.data });
    }

    handleBatal(data) {
        this.setState({ Detail: data });
        openModal("modalBatal");
    }

    async handleModalInfo(data) {
        let sql = await api("controler/ppdb_api", { act: "detail calon murid", ID: data.ID }, true);
        if (sql.status == "sukses") {
            this.setState({ Detail: sql.data });
            openModal("modalDetail");
        }
    }

    handleModalDaftarUlang(data) {
        this.setState({ Detail: data });
        openModal("modalDaftarUlang");
    }

    handleModalTerima(data) {
        this.setState({ Detail: data });
        openModal("modalTerima");
    }

    modalFormEdit(data) {
        this.setState({ Detail: data });
        openModal("modalFormEdit");
    }

    handlePilihMurid(data) {
        let chk = document.getElementById(`chkTable${data.ID}`);
        let Menu = this.state.DataMuridDaftarUlang;
        let Akses = [];
        for (let dd in Menu) {
            if (Menu[dd].ID == data.ID) {
                Menu[dd].checked = chk.checked;
            }
            if (Menu[dd].checked == true) Akses.push(Menu[dd]);
        }
        this.setState({ DataMuridDaftarUlang: Menu, ArrMurid: Akses });
    }

    async modalTerimaMurid() {
        let sql = await api("controler/ppdb_api", { act: "data murid daftar ulang" }, true);
        if (sql.status == "sukses") {
            this.setState({ DataMuridDaftarUlang: sql.data });
            openModal("modalTerimaMurid");
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <Fragment>
                            <div className="row">
                                <div className="col-md-8 main-title">{this.state.Title}</div>
                                <div className="col-md-4 d-flex gap-1">
                                    <select className='form-select form-select-sm' value={this.state.IDTahunAjaran} onChange={(e) => {
                                        this.setState({ IDTahunAjaran: e.target.value });
                                        setTimeout(() => {
                                            this.handleGetData();
                                        }, 500);
                                    }} >
                                        {
                                            this.state.DataTahunAjaran.map((opt, i) => {
                                                return <option key={i} value={opt.ID}>{opt.Nama}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <p></p>
                            <div className="row g-3 justify-content-center">
                                <div className="col-md-2">
                                    <div className="card text-center gradient-success text-white" onClick={() => this.handleChangeMode("Baru")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTBaru} Calon Siswa</p>
                                            <p className="card-text">Pendaftar Online</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-primary text-white" onClick={() => this.handleChangeMode("Beli Formulir")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTBeli} Calon Siswa</p>
                                            <p className="card-text">Pembeli Formulir</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-warning text-white" onClick={() => this.handleChangeMode("Sudah Isi Formulir")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTIsi} Calon Siswa</p>
                                            <p className="card-text">Sudah Isi Formulir</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-danger text-white" onClick={() => this.handleChangeMode("Daftar Ulang")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTDaftar} Calon Siswa</p>
                                            <p className="card-text">Sudah Daftar Ulang</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="card text-center gradient-dark text-white" onClick={() => this.handleChangeMode("Pendaftaran Diterima")}>
                                        <div className="card-body">
                                            <p className="card-text">{this.state.CTDiterima} Calon Siswa</p>
                                            <p className="card-text">Pendaftar Diterima</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <button className={this.state.Mode == "Baru" ? "btn btn-default" : "d-none"} onClick={() => this.modalFormEditSiswa({})}>Tambah Calon Siswa</button>
                            <button className={this.state.Mode == "Daftar Ulang" ? "btn btn-default" : "d-none"} onClick={() => this.modalTerimaMurid({})}>Terima Murid</button>
                            <p></p>
                            <RendTables
                                tbody={this.state.Data}
                                thead={this.state.Field}
                                opt={this.state.Opt} />
                        </Fragment>
                    </div>
                </div>

                <div className="modal fade" id="modalFormEditSiswa" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah calon siswa" : "edit calon siswa"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Calon Siswa" : "Edit Calon Siswa"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tahun Ajaran</label>
                                                <select className='form-select' name="IDTahunAjaran" value={this.state.Detail.IDTahunAjaran || "0"} onChange={(e) => {
                                                    this.getDataPPDB(e.target.value);
                                                    let data = this.state.Detail;
                                                    data.IDTahunAjaran = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="">Silahhkan pilih tahun ajaran</option>
                                                    {
                                                        this.state.DataTahunAjaran.map((opt, i) => {
                                                            return <option value={opt.ID} key={opt.ID}>{opt.Nama}</option>
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih tahun ajaran</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Gelombang PPDB</label>
                                                <select className='form-select' name="IDPPDB" value={this.state.Detail.IDPPDB || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDPPDB = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="">Silahkan pilih Gelombang ppdb</option>
                                                    {
                                                        this.state.DataPPDB.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Gelombang}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih gelombang ppdb</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control' name="NAMA" value={this.state.Detail.NAMA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Alamat</label>
                                                <input type="text" className='form-control' name="ALAMAT" value={this.state.Detail.ALAMAT || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Alamat</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>EMAIL</label>
                                                <input type="text" className='form-control' name="EMAIL" value={this.state.Detail.EMAIL || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.EMAIL = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan EMAIL</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type="number" className='form-control' name="NO_HANDPHONE" value={this.state.Detail.NO_HANDPHONE || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_HANDPHONE = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Telp</div>
                                            </div>
                                        </div>
                                        {
                                            this.state.Mode == "Sudah Isi Formulir" ?
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className='form-group'>
                                                            <label>NIK Siswa</label>
                                                            <input type="text" className='form-control' name="NIK" value={this.state.Detail.NIK || ""} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.NIK = e.target.value;
                                                                this.setState({ Detail: data });
                                                            }} required />
                                                            <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className='form-group'>
                                                            <label>NISN</label>
                                                            <input type="number" className='form-control' name="NISN" value={this.state.Detail.NISN || ""} onChange={(e) => {
                                                                let data = this.state.Detail;
                                                                data.NISN = e.target.value;
                                                                this.setState({ Detail: data });
                                                            }} required />
                                                            <div className='invalid-feedback'>Silahkan masukan NISN</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalBeliFormulir" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: (data) => this.handleSuccessBeliFormulir(data), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.IDPPDB} />
                                <input type='hidden' name="Pwd" value={this.state.Detail.Pwd} />
                                <input type='hidden' name="act" value="beli formulir" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Konfirmasi</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <table className='table table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>Nama</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.NAMA}</td>
                                            </tr>
                                            <tr>
                                                <td>Telp</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.NO_HANDPHONE}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.EMAIL}</td>
                                            </tr>
                                            <tr>
                                                <td>Alamat</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.ALAMAT}</td>
                                            </tr>
                                            <tr>
                                                <td>Biaya Beli Formulir</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.Detail.HargaFormulir)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalFormulir'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-print"></i> Cetak</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Informasi</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='table-responsive'>
                                    <table className='table table-striped'>
                                        <tbody>
                                            {Object.keys(this.state.Detail).map((key, i) => {
                                                if (key != "Pwd") {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{key}</td>
                                                            <td>:</td>
                                                            <td>{this.state.Detail[key]}</td>
                                                        </tr>
                                                    )
                                                }
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDaftarUlang" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.IDPPDB} />
                                <input type='hidden' name="act" value="proses daftar ulang" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Konfirmasi Daftar Ulang</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>Apakah anda yakin akan memproses daftar ulang ini !!</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalDaftarUlang'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Proses</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalTerima" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.IDPPDB} />
                                <input type='hidden' name="act" value="proses penerimaan" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Konfirmasi Penerimaan</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>Apakah anda yakin akan memproses penerimaan siswa ini !!</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalTerima'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Terima</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalBatal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.IDPPDB} />
                                <input type='hidden' name="Status" value={this.state.Mode} />
                                <input type='hidden' name="act" value="batal" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Konfirmasi Pembatalan</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>Apakah anda yakin akan membatalkan proses ini !!</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalBatal'>Tutup</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-window-close"></i> Batal</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermurid_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value="hapus master murid" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Siswa</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>Apakah anda yakin akan menghapus data ini !!</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalFormEdit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="update formulir" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Edit Murid</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA" value={this.state.Detail.NAMA} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Email</label>
                                                <input type="email" className='form-control form-control-sm' name="EMAIL" value={this.state.Detail.EMAIL} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.EMAIL = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Email</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Telp</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_HANDPHONE" value={this.state.Detail.NO_HANDPHONE} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_HANDPHONE = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_HANDPHONE</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT" value={this.state.Detail.ALAMAT} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR" value={this.state.Detail.TEMPAT_LAHIR} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR)} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK" value={this.state.Detail.NIK} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NISN</label>
                                                <input type="text" className='form-control form-control-sm' name="NISN" value={this.state.Detail.NISN} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NISN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NISN</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jenis Kelamin</label>
                                                <input type="text" className='form-control form-control-sm' name="JENIS_KELAMIN" value={this.state.Detail.JENIS_KELAMIN} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JENIS_KELAMIN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JENIS_KELAMIN</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Agama</label>
                                                <input type="text" className='form-control form-control-sm' name="AGAMA" value={this.state.Detail.AGAMA} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.AGAMA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan AGAMA</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Alamat</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_ALAMAT" value={this.state.Detail.STATUS_ALAMAT} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_ALAMAT = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_ALAMAT</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tlp. Orang Tua</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_HP_ORANG_TUA" value={this.state.Detail.NO_HP_ORANG_TUA} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_HP_ORANG_TUA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_HP_ORANG_TUA</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jarak Ke Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="JARAK_KE_SEKOLAH" value={this.state.Detail.JARAK_KE_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JARAK_KE_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JARAK_KE_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Transportasi Ke Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="TRANSPORTASI_KE_SEKOLAH" value={this.state.Detail.TRANSPORTASI_KE_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TRANSPORTASI_KE_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TRANSPORTASI_KE_SEKOLAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Cita-cita</label>
                                                <input type="text" className='form-control form-control-sm' name="CITA_CITA" value={this.state.Detail.CITA_CITA} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.CITA_CITA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan CITA_CITA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Hobby</label>
                                                <input type="text" className='form-control form-control-sm' name="HOBBY" value={this.state.Detail.HOBBY} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.HOBBY = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan HOBBY</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Asal Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="ASAL_SEKOLAH" value={this.state.Detail.ASAL_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ASAL_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ASAL_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_SEKOLAH" value={this.state.Detail.NAMA_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_SEKOLAH</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_SEKOLAH" value={this.state.Detail.ALAMAT_SEKOLAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>No. UN</label>
                                                <input type="text" className='form-control form-control-sm' name="NOMOR_UN" value={this.state.Detail.NOMOR_UN} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NOMOR_UN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NOMOR_UN</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Keluarga</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_KELUARGA" value={this.state.Detail.STATUS_KELUARGA} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_KELUARGA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS KELUARGA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Anak ke</label>
                                                <input type="text" className='form-control form-control-sm' name="ANAK_KE" value={this.state.Detail.ANAK_KE} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ANAK_KE = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ANAK KE</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jumlah Saudara</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_SAUDARA" value={this.state.Detail.JUMLAH_SAUDARA} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JUMLAH_SAUDARA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Jumlah SAUDARA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>No. KK</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_KK" value={this.state.Detail.NO_KK} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_KK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_KK</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_AYAH" value={this.state.Detail.NAMA_AYAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Tgl. Lahir Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_AYAH" value={this.state.Detail.TEMPAT_LAHIR_AYAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_AYAH</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Ayah</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_AYAH" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_AYAH)} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_AYAH" value={this.state.Detail.NIK_AYAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_AYAH" value={this.state.Detail.PENDIDIKAN_AYAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENDIDIKAN_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_AYAH" value={this.state.Detail.STATUS_AYAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekerjaan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_AYAH" value={this.state.Detail.PEKERJAAN_AYAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PEKERJAAN_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_AYAH" value={this.state.Detail.PENGHASILAN_AYAH} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENGHASILAN_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_IBU" value={this.state.Detail.NAMA_IBU} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_IBU" value={this.state.Detail.TEMPAT_LAHIR_IBU} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_IBU</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Ibu</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_IBU" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_IBU)} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_IBU" value={this.state.Detail.NIK_IBU} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_IBU" value={this.state.Detail.PENDIDIKAN_IBU} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENDIDIKAN_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_IBU" value={this.state.Detail.STATUS_IBU} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekejraan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_IBU" value={this.state.Detail.PEKERJAAN_IBU} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PEKERJAAN_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_IBU" value={this.state.Detail.PENGHASILAN_IBU} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENGHASILAN_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Orang Tua</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_ORANG_TUA" value={this.state.Detail.ALAMAT_ORANG_TUA} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT_ORANG_TUA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_ORANG_TUA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jumlah Tanggungan</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN" value={this.state.Detail.JUMLAH_TANGGUNGAN} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JUMLAH_TANGGUNGAN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JUMLAH_TANGGUNGAN</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Kartu Jaminan</label>
                                                <input type="text" className='form-control form-control-sm' name="KARTU_JAMINAN" value={this.state.Detail.KARTU_JAMINAN} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.KARTU_JAMINAN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan KARTU_JAMINAN</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_WALI" value={this.state.Detail.NAMA_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_WALI</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_WALI" value={this.state.Detail.TEMPAT_LAHIR_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Wali</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_WALI" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_WALI)} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_WALI" value={this.state.Detail.NIK_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_WALI" value={this.state.Detail.PENDIDIKAN_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENDIDIKAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Telp Wali</label>
                                                <input type="number" className='form-control form-control-sm' name="TELP_WALI" value={this.state.Detail.TELP_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TELP_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan telp wali</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Hubungan Dengan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="HUBUNGAN_WALI" value={this.state.Detail.HUBUNGAN_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.HUBUNGAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan hubungan dengan wali</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekerjaan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_WALI" value={this.state.Detail.PEKERJAAN_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PEKERJAAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_WALI" value={this.state.Detail.PENGHASILAN_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENGHASILAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_WALI" value={this.state.Detail.ALAMAT_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jml. Tanggungan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN_WALI" value={this.state.Detail.JUMLAH_TANGGUNGAN_WALI} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JUMLAH_TANGGUNGAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group form-group-sm'>
                                        <label>JPAUD</label>
                                        <select className='form-select form-select-sm' name="PAUD" value={this.state.Detail.PAUD} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.PAUD = e.target.value;
                                            this.setState({ Detail: data });
                                        }} >
                                            <option value="Ya">Ya</option>
                                            <option value="Tidak">Tidak</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan pilih</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormEdit'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalTerimaMurid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/ppdb_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value="proses penerimaan masal" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Terima Siswa Masal</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <RendTables
                                        tbody={this.state.DataMuridDaftarUlang}
                                        thead={[
                                            { cap: "", sort: "", type: "opsi" },
                                            { cap: "NIK", sort: "NIK", type: "str" },
                                            { cap: "NAMA", sort: "NAMA", type: "str" },
                                            { cap: "TELP", sort: "NO_HANDPHONE", type: "str" },
                                            { cap: "EMAIL", sort: "EMAIL", type: "str" },
                                            { cap: "ALAMAT", sort: "ALAMAT", type: "str" },
                                        ]}
                                        opt={[
                                            { icon: "checkbox", fn: (e) => this.handlePilihMurid(e), name: "IDPPDB[]", value: "IDPPDB" },
                                        ]}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalTerimaAll'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
