import React, { Fragment } from 'react';
import { api, submitForm, openModal, checkDateFormat, exportData, importData, saiki } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataImport: [],
            DataWali: [],
            DataKelas: [],
            Detail: {},
            q: "",
            Akses: true,
            ExpandGrid: false
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/mastermurid_api", { act: "data master murid", q: this.state.q });
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });

        }
    }

    async handleModalForm(Detail = { KelasID: "", ID: "" }) {
        let Details = {
            NAMA: "",
            EMAIL: "",
            NO_HANDPHONE: "",
            ALAMAT: "",
            TEMPAT_LAHIR: "",
            TANGGAL_LAHIR: saiki(),
            NIK: "",
            NISN: "",
            JENIS_KELAMIN: "",
            AGAMA: "",
            STATUS_ALAMAT: "",
            NO_HP_ORANG_TUA: "",
            JARAK_KE_SEKOLAH: "",
            TRANSPORTASI_KE_SEKOLAH: "",
            CITA_CITA: "",
            HOBBY: "",
            ASAL_SEKOLAH: "",
            NAMA_SEKOLAH: "",
            ALAMAT_SEKOLAH: "",
            NOMOR_UN: "",
            STATUS_KELUARGA: "",
            ANAK_KE: "",
            JUMLAH_SAUDARA: "",
            NO_KK: "",
            NAMA_AYAH: "",
            TEMPAT_LAHIR_AYAH: "",
            TANGGAL_LAHIR_AYAH: saiki(),
            NIK_AYAH: "",
            PENDIDIKAN_AYAH: "",
            STATUS_AYAH: "",
            PEKERJAAN_AYAH: "",
            PENGHASILAN_AYAH: "",
            NAMA_IBU: "",
            TEMPAT_LAHIR_IBU: "",
            TANGGAL_LAHIR_IBU: saiki(),
            NIK_IBU: "",
            PENDIDIKAN_IBU: "",
            STATUS_IBU: "",
            PEKERJAAN_IBU: "",
            PENGHASILAN_IBU: "",
            ALAMAT_ORANG_TUA: "",
            JUMLAH_TANGGUNGAN: "",
            KARTU_JAMINAN: "",
            NAMA_WALI: "",
            TEMPAT_LAHIR_WALI: "",
            TANGGAL_LAHIR_WALI: saiki(),
            NIK_WALI: "",
            PENDIDIKAN_WALI: "",
            PEKERJAAN_WALI: "",
            PENGHASILAN_WALI: "",
            HUBUNGAN_WALI: "",
            TELP_WALI: "",
            ALAMAT_WALI: "",
            JUMLAH_TANGGUNGAN_WALI: "",
            PAUD: "",
        };
        let sql = await api("controler/mastermurid_api", { act: "detail master murid", ID: Detail.ID });
        this.setState({ Detail: sql.data || Details, DataWali: sql.wali, DataKelas: sql.kelas });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handleImEx(ACT, Type) {
        if (ACT == "Export") {
            let data = [], title = "";
            if (Type == "contoh") {
                data = [{ NAMA: "Lailatul Nadhifa", EMAIL: "nadhifa@gmail.com", NO_HANDPHONE: "085704135172", ALAMAT: "Wedoro Utara, Rt.2 , Rw02", TEMPAT_LAHIR: "Sidoarjo", TANGGAL_LAHIR: "17-12-2007", NIK: "3515185712070003", NISN: "-", JENIS_KELAMIN: "Perempuan", AGAMA: "Islam", STATUS_ALAMAT: "orang tua", NO_HP_ORANG_TUA: "085704135172", JARAK_KE_SEKOLAH: "5km-6km", TRANSPORTASI_KE_SEKOLAH: "motor", CITA_CITA: "Menjadi Translator", HOBBY: "Mendengarkan lagu", ASAL_SEKOLAH: "SMP", NAMA_SEKOLAH: "SMP BUANA WARU", ALAMAT_SEKOLAH: "Jl. Kolonel Sugiono No.2-A, Belahan, Wedoro, Kec. Waru, Kabupaten Sidoarjo", NOMOR_UN: "", STATUS_KELUARGA: "anak kandung", ANAK_KE: "2", JUMLAH_SAUDARA: "2", NO_KK: "3515182901092301", NAMA_AYAH: "IMAM  SYAFI'I", TEMPAT_LAHIR_AYAH: "Sidoarjo", TANGGAL_LAHIR_AYAH: "03-01-1974", NIK_AYAH: "3515180301740001", PENDIDIKAN_AYAH: "sma/ma", STATUS_AYAH: "hidup", PEKERJAAN_AYAH: "Wiraswasta", PENGHASILAN_AYAH: "1-2jt", NAMA_IBU: "ISTIKANAH", TEMPAT_LAHIR_IBU: "Mojokerto", TANGGAL_LAHIR_IBU: "18-08-1975", NIK_IBU: "3515185808750003", PENDIDIKAN_IBU: "smp/mts", STATUS_IBU: "hidup", PEKERJAAN_IBU: "Rumah Tangga", PENGHASILAN_IBU: "tidak punya", ALAMAT_ORANG_TUA: "-", JUMLAH_TANGGUNGAN: "-", KARTU_JAMINAN: "-", NAMA_WALI: "-", TEMPAT_LAHIR_WALI: "-", TANGGAL_LAHIR_WALI: "-", NIK_WALI: "-", PENDIDIKAN_WALI: "-", PEKERJAAN_WALI: "-", PENGHASILAN_WALI: "-", ALAMAT_WALI: "-", JUMLAH_TANGGUNGAN_WALI: "-", PAUD: "Ya" }];
                title = "Contoh Format Import Siswa";
            } else {
                let sql = await api("controler/mastermurid_api", { act: "data master murid", q: "" }, true);
                if (sql.status == "sukses") data = sql.data;
                title = "Data Siswa";
            }
            let Field = [
                { cap: "", sort: "", type: "opsi" },
                { sort: "NAMA", cap: "NAMA", type: "text" },
                { sort: "EMAIL", cap: "EMAIL", type: "text" },
                { sort: "NO_HANDPHONE", cap: "NO_HANDPHONE", type: "text" },
                { sort: "ALAMAT", cap: "ALAMAT", type: "text" },
                { sort: "TEMPAT_LAHIR", cap: "TEMPAT_LAHIR", type: "text" },
                { sort: "TANGGAL_LAHIR", cap: "TANGGAL_LAHIR", type: "text" },
                { sort: "NIK", cap: "NIK", type: "text" },
                { sort: "NISN", cap: "NISN", type: "text" },
                { sort: "JENIS_KELAMIN", cap: "JENIS_KELAMIN", type: "text" },
                { sort: "AGAMA", cap: "AGAMA", type: "text" },
                { sort: "STATUS_ALAMAT", cap: "STATUS_ALAMAT", type: "text" },
                { sort: "NO_HP_ORANG_TUA", cap: "NO_HP_ORANG_TUA", type: "text" },
                { sort: "JARAK_KE_SEKOLAH", cap: "JARAK_KE_SEKOLAH", type: "text" },
                { sort: "TRANSPORTASI_KE_SEKOLAH", cap: "TRANSPORTASI_KE_SEKOLAH", type: "text" },
                { sort: "CITA_CITA", cap: "CITA_CITA", type: "text" },
                { sort: "HOBBY", cap: "HOBBY", type: "text" },
                { sort: "ASAL_SEKOLAH", cap: "ASAL_SEKOLAH", type: "text" },
                { sort: "NAMA_SEKOLAH", cap: "NAMA_SEKOLAH", type: "text" },
                { sort: "ALAMAT_SEKOLAH", cap: "ALAMAT_SEKOLAH", type: "text" },
                { sort: "NOMOR_UN", cap: "NOMOR_UN", type: "text" },
                { sort: "STATUS_KELUARGA", cap: "STATUS_KELUARGA", type: "text" },
                { sort: "ANAK_KE", cap: "ANAK_KE", type: "text" },
                { sort: "JUMLAH_SAUDARA", cap: "JUMLAH_SAUDARA", type: "text" },
                { sort: "NO_KK", cap: "NO_KK", type: "text" },
                { sort: "NAMA_AYAH", cap: "NAMA_AYAH", type: "text" },
                { sort: "TEMPAT_LAHIR_AYAH", cap: "TEMPAT_LAHIR_AYAH", type: "text" },
                { sort: "TANGGAL_LAHIR_AYAH", cap: "TANGGAL_LAHIR_AYAH", type: "text" },
                { sort: "NIK_AYAH", cap: "NIK_AYAH", type: "text" },
                { sort: "PENDIDIKAN_AYAH", cap: "PENDIDIKAN_AYAH", type: "text" },
                { sort: "STATUS_AYAH", cap: "STATUS_AYAH", type: "text" },
                { sort: "PEKERJAAN_AYAH", cap: "PEKERJAAN_AYAH", type: "text" },
                { sort: "PENGHASILAN_AYAH", cap: "PENGHASILAN_AYAH", type: "text" },
                { sort: "NAMA_IBU", cap: "NAMA_IBU", type: "text" },
                { sort: "TEMPAT_LAHIR_IBU", cap: "TEMPAT_LAHIR_IBU", type: "text" },
                { sort: "TANGGAL_LAHIR_IBU", cap: "TANGGAL_LAHIR_IBU", type: "text" },
                { sort: "NIK_IBU", cap: "NIK_IBU", type: "text" },
                { sort: "PENDIDIKAN_IBU", cap: "PENDIDIKAN_IBU", type: "text" },
                { sort: "STATUS_IBU", cap: "STATUS_IBU", type: "text" },
                { sort: "PEKERJAAN_IBU", cap: "PEKERJAAN_IBU", type: "text" },
                { sort: "PENGHASILAN_IBU", cap: "PENGHASILAN_IBU", type: "text" },
                { sort: "ALAMAT_ORANG_TUA", cap: "ALAMAT_ORANG_TUA", type: "text" },
                { sort: "JUMLAH_TANGGUNGAN", cap: "JUMLAH_TANGGUNGAN", type: "text" },
                { sort: "KARTU_JAMINAN", cap: "KARTU_JAMINAN", type: "text" },
                { sort: "NAMA_WALI", cap: "NAMA_WALI", type: "text" },
                { sort: "HUBUNGAN_WALI", cap: "HUBUNGAN_WALI", type: "text" },
                { sort: "TEMPAT_LAHIR_WALI", cap: "TEMPAT_LAHIR_WALI", type: "text" },
                { sort: "TANGGAL_LAHIR_WALI", cap: "TANGGAL_LAHIR_WALI", type: "text" },
                { sort: "NIK_WALI", cap: "NIK_WALI", type: "text" },
                { sort: "PENDIDIKAN_WALI", cap: "PENDIDIKAN_WALI", type: "text" },
                { sort: "PEKERJAAN_WALI", cap: "PEKERJAAN_WALI", type: "text" },
                { sort: "PENGHASILAN_WALI", cap: "PENGHASILAN_WALI", type: "text" },
                { sort: "TELP_WALI", cap: "TELP_WALI", type: "text" },
                { sort: "ALAMAT_WALI", cap: "ALAMAT_WALI", type: "text" },
                { sort: "JUMLAH_TANGGUNGAN_WALI", cap: "JUMLAH_TANGGUNGAN_WALI", type: "text" },
                { sort: "PAUD", cap: "PAUD", type: "text" },
                { sort: "Kelas", cap: "Kelas", type: "text" },
            ];
            exportData(data, title, Field);
        } else {
            openModal("modalImport");
        }
    }

    async handleLoadExcel(e) {
        let Data = await importData(e.target.files[0]);
        this.setState({ DataImport: Data });
    }

    async handleProsesImport(e) {
        e.target.disabled = true;
        let Data = this.state.DataImport;
        let ct = 0;
        for (let dd of Data) {
            let sql = await api("model/mastermurid_crud", {
                act: "tambah master murid",
                NAMA: dd.NAMA,
                EMAIL: dd.EMAIL,
                NO_HANDPHONE: dd.NO_HANDPHONE,
                ALAMAT: dd.ALAMAT,
                TEMPAT_LAHIR: dd.TEMPAT_LAHIR,
                TANGGAL_LAHIR: checkDateFormat(dd.TANGGAL_LAHIR),
                NIK: dd.NIK,
                NISN: dd.NISN,
                JENIS_KELAMIN: dd.JENIS_KELAMIN,
                AGAMA: dd.AGAMA,
                STATUS_ALAMAT: dd.STATUS_ALAMAT,
                NO_HP_ORANG_TUA: dd.NO_HP_ORANG_TUA,
                JARAK_KE_SEKOLAH: dd.JARAK_KE_SEKOLAH,
                TRANSPORTASI_KE_SEKOLAH: dd.TRANSPORTASI_KE_SEKOLAH,
                CITA_CITA: dd.CITA_CITA,
                HOBBY: dd.HOBBY,
                ASAL_SEKOLAH: dd.ASAL_SEKOLAH,
                NAMA_SEKOLAH: dd.NAMA_SEKOLAH,
                ALAMAT_SEKOLAH: dd.ALAMAT_SEKOLAH,
                NOMOR_UN: dd.NOMOR_UN,
                STATUS_KELUARGA: dd.STATUS_KELUARGA,
                ANAK_KE: dd.ANAK_KE,
                JUMLAH_SAUDARA: dd.JUMLAH_SAUDARA,
                NO_KK: dd.NO_KK,
                NAMA_AYAH: dd.NAMA_AYAH,
                TEMPAT_LAHIR_AYAH: dd.TEMPAT_LAHIR_AYAH,
                TANGGAL_LAHIR_AYAH: checkDateFormat(dd.TANGGAL_LAHIR_AYAH),
                NIK_AYAH: dd.NIK_AYAH,
                PENDIDIKAN_AYAH: dd.PENDIDIKAN_AYAH,
                STATUS_AYAH: dd.STATUS_AYAH,
                PEKERJAAN_AYAH: dd.PEKERJAAN_AYAH,
                PENGHASILAN_AYAH: dd.PENGHASILAN_AYAH,
                NAMA_IBU: dd.NAMA_IBU,
                TEMPAT_LAHIR_IBU: dd.TEMPAT_LAHIR_IBU,
                TANGGAL_LAHIR_IBU: checkDateFormat(dd.TANGGAL_LAHIR_IBU),
                NIK_IBU: dd.NIK_IBU,
                PENDIDIKAN_IBU: dd.PENDIDIKAN_IBU,
                STATUS_IBU: dd.STATUS_IBU,
                PEKERJAAN_IBU: dd.PEKERJAAN_IBU,
                PENGHASILAN_IBU: dd.PENGHASILAN_IBU,
                ALAMAT_ORANG_TUA: dd.ALAMAT_ORANG_TUA,
                JUMLAH_TANGGUNGAN: dd.JUMLAH_TANGGUNGAN,
                KARTU_JAMINAN: dd.KARTU_JAMINAN,
                NAMA_WALI: dd.NAMA_WALI,
                TEMPAT_LAHIR_WALI: dd.TEMPAT_LAHIR_WALI,
                TANGGAL_LAHIR_WALI: checkDateFormat(dd.TANGGAL_LAHIR_WALI),
                NIK_WALI: dd.NIK_WALI,
                PENDIDIKAN_WALI: dd.PENDIDIKAN_WALI,
                PEKERJAAN_WALI: dd.PEKERJAAN_WALI,
                PENGHASILAN_WALI: dd.PENGHASILAN_WALI,
                HUBUNGAN_WALI: dd.HUBUNGAN_WALI,
                TELP_WALI: dd.TELP_WALI,
                ALAMAT_WALI: dd.ALAMAT_WALI,
                JUMLAH_TANGGUNGAN_WALI: dd.JUMLAH_TANGGUNGAN_WALI,
                PAUD: dd.PAUD,
            });
            Data[ct].status = sql.status;
            Data[ct].NAMA = sql.status == "sukses" ? "Berhasil" : sql.pesan;
            this.setState({ DataImport: Data });
            ct++;
        }
        e.target.disabled = false;
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-8 main-title">Master Murid</div>
                                    <div className="col-md-4">
                                        <div className="input-group">
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleImEx('Export')}><i className="fas fa-download"></i></button>
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleImEx('Import')}><i className="fas fa-upload"></i></button>
                                            <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Admin' />
                                            <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name='IsWajib' type="checkbox" role="switch" id="chkExpand" checked={this.state.ExpandGrid} onChange={(e) => this.setState({ ExpandGrid: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="chkExpand">Expand Tabel</label>
                                        </div>
                                    </div>
                                </div>
                                <RendTables tbody={this.state.Data}
                                    thead={this.state.Field}
                                    group={[
                                        { sort: "Kelas", cap: "Kelas" }
                                    ]}
                                    expandGroup={this.state.ExpandGrid}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e), cap: "Edit Siswa", color: "text-warning" },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e), cap: "Hapus Siswa", color: "text-danger" }
                                    ]} />
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Modal Form */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermurid_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master murid" : "edit master murid"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Murid" : "Edit Murid"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA" value={this.state.Detail.NAMA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Email</label>
                                                <input type="email" className='form-control form-control-sm' name="EMAIL" value={this.state.Detail.EMAIL || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.EMAIL = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Email</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Telp</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_HANDPHONE" value={this.state.Detail.NO_HANDPHONE || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_HANDPHONE = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_HANDPHONE</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT" value={this.state.Detail.ALAMAT || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR" value={this.state.Detail.TEMPAT_LAHIR || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR || "")} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK" value={this.state.Detail.NIK || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NISN</label>
                                                <input type="text" className='form-control form-control-sm' name="NISN" value={this.state.Detail.NISN || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NISN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NISN</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jenis Kelamin</label>
                                                <input type="text" className='form-control form-control-sm' name="JENIS_KELAMIN" value={this.state.Detail.JENIS_KELAMIN || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JENIS_KELAMIN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JENIS_KELAMIN</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Agama</label>
                                                <input type="text" className='form-control form-control-sm' name="AGAMA" value={this.state.Detail.AGAMA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.AGAMA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan AGAMA</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Alamat</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_ALAMAT" value={this.state.Detail.STATUS_ALAMAT || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_ALAMAT = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_ALAMAT</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tlp. Orang Tua</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_HP_ORANG_TUA" value={this.state.Detail.NO_HP_ORANG_TUA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_HP_ORANG_TUA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_HP_ORANG_TUA</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jarak Ke Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="JARAK_KE_SEKOLAH" value={this.state.Detail.JARAK_KE_SEKOLAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JARAK_KE_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JARAK_KE_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Transportasi Ke Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="TRANSPORTASI_KE_SEKOLAH" value={this.state.Detail.TRANSPORTASI_KE_SEKOLAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TRANSPORTASI_KE_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TRANSPORTASI_KE_SEKOLAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Cita-cita</label>
                                                <input type="text" className='form-control form-control-sm' name="CITA_CITA" value={this.state.Detail.CITA_CITA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.CITA_CITA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan CITA_CITA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Hobby</label>
                                                <input type="text" className='form-control form-control-sm' name="HOBBY" value={this.state.Detail.HOBBY || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.HOBBY = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan HOBBY</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Asal Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="ASAL_SEKOLAH" value={this.state.Detail.ASAL_SEKOLAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ASAL_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ASAL_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_SEKOLAH" value={this.state.Detail.NAMA_SEKOLAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_SEKOLAH</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Sekolah</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_SEKOLAH" value={this.state.Detail.ALAMAT_SEKOLAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT_SEKOLAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_SEKOLAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>No. UN</label>
                                                <input type="text" className='form-control form-control-sm' name="NOMOR_UN" value={this.state.Detail.NOMOR_UN || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NOMOR_UN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NOMOR_UN</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Keluarga</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_KELUARGA" value={this.state.Detail.STATUS_KELUARGA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_KELUARGA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS KELUARGA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Anak ke</label>
                                                <input type="text" className='form-control form-control-sm' name="ANAK_KE" value={this.state.Detail.ANAK_KE || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ANAK_KE = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ANAK KE</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jumlah Saudara</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_SAUDARA" value={this.state.Detail.JUMLAH_SAUDARA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JUMLAH_SAUDARA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Jumlah SAUDARA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>No. KK</label>
                                                <input type="text" className='form-control form-control-sm' name="NO_KK" value={this.state.Detail.NO_KK || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NO_KK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NO_KK</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_AYAH" value={this.state.Detail.NAMA_AYAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Tgl. Lahir Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_AYAH" value={this.state.Detail.TEMPAT_LAHIR_AYAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_AYAH</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Ayah</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_AYAH" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_AYAH || "")} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_AYAH" value={this.state.Detail.NIK_AYAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_AYAH" value={this.state.Detail.PENDIDIKAN_AYAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENDIDIKAN_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_AYAH" value={this.state.Detail.STATUS_AYAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekerjaan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_AYAH" value={this.state.Detail.PEKERJAAN_AYAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PEKERJAAN_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_AYAH</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Ayah</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_AYAH" value={this.state.Detail.PENGHASILAN_AYAH || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENGHASILAN_AYAH = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_AYAH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_IBU" value={this.state.Detail.NAMA_IBU || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_IBU" value={this.state.Detail.TEMPAT_LAHIR_IBU || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_IBU</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Ibu</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_IBU" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_IBU || "")} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_IBU" value={this.state.Detail.NIK_IBU || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_IBU" value={this.state.Detail.PENDIDIKAN_IBU || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENDIDIKAN_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="STATUS_IBU" value={this.state.Detail.STATUS_IBU || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.STATUS_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan STATUS_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekejraan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_IBU" value={this.state.Detail.PEKERJAAN_IBU || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PEKERJAAN_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_IBU</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Ibu</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_IBU" value={this.state.Detail.PENGHASILAN_IBU || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENGHASILAN_IBU = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_IBU</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Orang Tua</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_ORANG_TUA" value={this.state.Detail.ALAMAT_ORANG_TUA || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT_ORANG_TUA = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_ORANG_TUA</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jumlah Tanggungan</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN" value={this.state.Detail.JUMLAH_TANGGUNGAN || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JUMLAH_TANGGUNGAN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan JUMLAH_TANGGUNGAN</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Kartu Jaminan</label>
                                                <input type="text" className='form-control form-control-sm' name="KARTU_JAMINAN" value={this.state.Detail.KARTU_JAMINAN || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.KARTU_JAMINAN = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan KARTU_JAMINAN</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Nama Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="NAMA_WALI" value={this.state.Detail.NAMA_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NAMA_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NAMA_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Hubungan Dengan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="HUBUNGAN_WALI" value={this.state.Detail.HUBUNGAN_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.HUBUNGAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan hubungan dengan wali</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>No. Telp Wali</label>
                                                <input type="number" className='form-control form-control-sm' name="TELP_WALI" value={this.state.Detail.TELP_WALI || "" || "0"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TELP_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan No. telp wali</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tempat Lahir Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="TEMPAT_LAHIR_WALI" value={this.state.Detail.TEMPAT_LAHIR_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TEMPAT_LAHIR_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TEMPAT_LAHIR_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Tgl. Lahir Wali</label>
                                                <input type="date" className='form-control form-control-sm' name="TANGGAL_LAHIR_WALI" value={checkDateFormat(this.state.Detail.TANGGAL_LAHIR_WALI || "")} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TANGGAL_LAHIR_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TANGGAL_LAHIR_WALI</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>NIK Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="NIK_WALI" value={this.state.Detail.NIK_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pendidikan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PENDIDIKAN_WALI" value={this.state.Detail.PENDIDIKAN_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENDIDIKAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENDIDIKAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Pekerjaan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PEKERJAAN_WALI" value={this.state.Detail.PEKERJAAN_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PEKERJAAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PEKERJAAN_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Penghasilan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="PENGHASILAN_WALI" value={this.state.Detail.PENGHASILAN_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PENGHASILAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Alamat Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="ALAMAT_WALI" value={this.state.Detail.ALAMAT_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.ALAMAT_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan ALAMAT_WALI</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Jml. Tanggungan Wali</label>
                                                <input type="text" className='form-control form-control-sm' name="JUMLAH_TANGGUNGAN_WALI" value={this.state.Detail.JUMLAH_TANGGUNGAN_WALI || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JUMLAH_TANGGUNGAN_WALI = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan PENGHASILAN_WALI</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Kelas</label>
                                                <select className='form-select form-select-sm' name="KelasID" value={this.state.Detail.KelasID || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.KelasID = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih kelas</option>
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih kelas</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Wali</label>
                                                <select className='form-select form-select-sm' name="WALI_ID" value={this.state.Detail.WALI_ID || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.WALI_ID = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    {
                                                        this.state.DataWali.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>JPAUD</label>
                                                <select className='form-select form-select-sm' name="PAUD" value={this.state.Detail.PAUD || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.PAUD = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="Ya">Ya</option>
                                                    <option value="Tidak">Tidak</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group form-group-sm'>
                                                <label>Status</label>
                                                <select className='form-select form-select-sm' name="Status" value={this.state.Detail.Status || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Status = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="Aktif">Aktif</option>
                                                    <option value="Lulus">Lulus</option>
                                                    <option value="Pindah">Pindah</option>
                                                    <option value="DO">DO</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* modal import */}
                <div className="modal fade" id="modalImport" tabIndex="-1" aria-labelledby="modalImport" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Import Data</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm">
                                    <input type="file" className="form-control form-control-sm" id="edtFileExcel" accept=".xls, .xlsx" onChange={(e) => this.handleLoadExcel(e)} />
                                    <button type='button' className='btn btn-sm btn-default' onClick={(e) => this.handleImEx("Export", 'contoh')}>Contoh Format</button>
                                </div>
                                <p></p>
                                <div className="table-responsive" style={{ height: "700px", maxHeight: "700px", overflowY: "auto" }}>
                                    <table className="table table-stripped table-sm">
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.Field.map((th, i) => {
                                                        return <th key={i}>{th.cap}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataImport.map((rr, i) => {
                                                    return <tr key={i}>
                                                        {
                                                            this.state.Field.map((dd, ii) => {
                                                                if (rr.status && dd.sort == "NAMA") {
                                                                    if (rr.status == "sukses") {
                                                                        return <td key={ii}>
                                                                            <span className='badge bg-sucess'>{rr[dd.sort]}</span>
                                                                        </td>
                                                                    } else {
                                                                        return <td key={ii}>
                                                                            <span className='badge bg-danger'>{rr[dd.sort]}</span>
                                                                        </td>
                                                                    }
                                                                } else {
                                                                    return <td key={ii}>{rr[dd.sort]}</td>
                                                                }
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-default" onClick={(e) => this.handleProsesImport(e, "mapel")}><i className='fas fa-save'></i> Proses</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* modal delete */}
                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/mastermurid_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID || ""} />
                                <input type='hidden' name="act" value="hapus master menu" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Menu</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus siswa !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
