import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, tanggalIndo } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataGroup: [],
            Detail: {},
            DataSemester: [],
            DetailSemester: {},
            q: "",
            Akses: true,
            DeleteAct: "hapus tahun ajaran",
            ID: ""
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/kurikulum_api", { act: "data tahun ajaran", q: this.state.q }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = { TanggalMulai: saiki(null, "Month"), TanggalSelesai: saiki(null, 'Month') }) {
        let sql = await api("controler/kurikulum_api", { act: "detail tahun ajaran", ID: Detail.ID }, true);
        this.setState({ Detail: sql.data || Detail, DataSemester: sql.semester });
        openModal("modalFormMenu");
    }

    modalDelete(Detail, act = "hapus tahun ajaran") {
        this.setState({ ID: Detail.ID, DeleteAct: act });
        openModal("modalDelete")
    }

    async handleSemester(data) {
        let sql = await api("controler/kurikulum_api", { act: "detail tahun ajaran", ID: data.ID }, true);
        if (sql.status == "sukses") {
            this.setState({ Detail: sql.data, DataSemester: sql.semester });
            openModal("modalSemester");
        }
    }

    async formSemester(data) {
        this.setState({ DetailSemester: data });
        openModal("modalFormSemester");
    }

    async handleGetDetail() {
        let sql = await api("controler/kurikulum_api", { act: "detail tahun ajaran", ID: this.state.Detail.ID }, true);
        this.setState({ Detail: sql.data, DataSemester: sql.semester });
    }

    handleAfterDelete() {
        if (this.state.DeleteAct == "hapus tahun ajaran") {
            this.handleMain();
        } else {
            this.handleGetDetail();
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ? <Fragment>
                            <div className="row">
                                <div className="col-md-8 main-title">Master Tahun Ajaran</div>
                                <div className="col-md-4 d-flex gap-1">
                                    <div className="input-group">
                                        <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                        <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Kelas' />
                                        <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            <RendTables
                                tbody={this.state.Data}
                                thead={this.state.Field}
                                opt={[
                                    { icon: "fas fa-calendar-week", fn: (e) => this.handleSemester(e) },
                                    { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                    { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                ]} />
                        </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah tahun ajaran" : "edit tahun ajaran"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Tahun Ajaran" : "Edit Tahun Ajaran"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control' name="Nama" id="edtNama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Nama = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tahun</label>
                                                <input type="number" className='form-control' name="Tahun" id="Tahun" value={this.state.Detail.Tahun || saiki(null, "year")} maxLength={4} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tahun = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tahun</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Mulai</label>
                                                <input type="month" className='form-control' name="TanggalMulai" id="edtTanggalMulai" value={this.state.Detail.TanggalMulai || saiki(null, "month")} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalMulai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Mulai</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Selesai</label>
                                                <input type="month" className='form-control' name="TanggalSelesai" id="edtTanggalSelesai" value={this.state.Detail.TanggalSelesai || saiki(null, "month")} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalSelesai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Selesai</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.Detail.Status || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.value;
                                            this.setState({ Detail: data });
                                        }} >
                                            <option value="Aktif">Aktif</option>
                                            <option value="Tidak Aktif">Tidak Aktif</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalSemester" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Semester" : "Edit Semester"}</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <button type="button" className="btn btn-default" id="btnAddSemester" onClick={() => this.formSemester({})}>Tambah Semester</button>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Mulai</th>
                                                <th>Selesai</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataSemester.map((tr, i) => {
                                                    return (<tr key={i}>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tanggalIndo(tr.TanggalMulai)}</td>
                                                        <td>{tanggalIndo(tr.TanggalSelesai)}</td>
                                                        <td>{tr.Status}</td>
                                                        <td>
                                                            <button className='btn' type='button' onClick={() => this.formSemester(tr)}>
                                                                <i className='fas fa-edit'></i>
                                                            </button>
                                                            <button type="button" className="btn" onClick={() => this.modalDelete(tr, "hapus semester")}>
                                                                <i className='fas fa-trash'></i>
                                                            </button>
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalSemester'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormSemester" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleGetDetail(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.DetailSemester.ID} />
                                <input type='hidden' name="act" value={this.state.DetailSemester.ID == undefined ? "tambah semester" : "edit semester"} />
                                <input type='hidden' name="IDTahunAjaran" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.DetailSemester.ID == undefined ? "Tambah Semester" : "Edit Semester"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Nama</label>
                                        <input type="text" className='form-control' name="Nama" id="edtNama" value={this.state.DetailSemester.Nama || ""} onChange={(e) => {
                                            let data = this.state.DetailSemester;
                                            data.Nama = e.target.value;
                                            this.setState({ DetailSemester: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Mulai</label>
                                                <input type="date" className='form-control' name="TanggalMulai" id="edtTanggalMulai" value={this.state.DetailSemester.TanggalMulai || saiki()} onChange={(e) => {
                                                    let data = this.state.DetailSemester;
                                                    data.TanggalMulai = e.target.value;
                                                    this.setState({ DetailSemester: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Mulai</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Selesai</label>
                                                <input type="date" className='form-control' name="TanggalSelesai" id="edtTanggalSelesai" value={this.state.DetailSemester.TanggalSelesai || saiki()} onChange={(e) => {
                                                    let data = this.state.DetailSemester;
                                                    data.TanggalSelesai = e.target.value;
                                                    this.setState({ DetailSemester: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Selesai</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.Detail.Status || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.value;
                                            this.setState({ Detail: data });
                                        }} >
                                            <option value="Aktif">Aktif</option>
                                            <option value="Draft">Draft</option>
                                            <option value="Selesai">Selesai</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormSemester'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleAfterDelete(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value={this.state.DeleteAct} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Tahun Ajaran</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h6>Apakah anda yakin akan menghapus data ini !!</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
