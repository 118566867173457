import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki } from '../Modul';
import RendTables from '../component/RendTable';
import DateRangeBox from 'devextreme-react/date-range-box';
let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Data: [],
            Field: [],
            Detail: { ID: "" },
            DetailBuku: [],
            q: "",
            D1: saiki("01"),
            D2: saiki(),
            DataSarana: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/perpus_api", { act: "data penghapusan buku", q: this.state.q, D1: this.state.D1, D2: this.state.D2, Status: this.state.Status }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/perpus_api", { act: "detail penghapusan buku", DocNumber: Detail.DocNumber }, true);
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DetailBuku: sql.detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    modalCariSarana() {
        openModal("modalSarana");
        this.handleCariBuku("");
    }

    async handleCariBuku(val = "") {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let sql = await api("controler/perpus_api", { act: "data master buku", q: val });
            if (sql.status == "sukses") this.setState({ DataSarana: sql.data });
        }, 500);

    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
    };

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-6 main-title">Penghapusan Buku</div>
                            <div className="col-md-6 d-flex align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <DateRangeBox
                                    defaultValue={[this.state.D1, this.state.D2]}
                                    onValueChanged={this.onCurrentValueChange}
                                />
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ cap: "Tanggal", sort: "Tanggal" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah penghapusan buku" : "edit penghapusan buku"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="DocNumber" value={this.state.Detail.DocNumber} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Penghapusan buku" : "Edit Penghapusan buku"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group'>
                                        <label>Tanggal Penghapusan</label>
                                        <input type="date" className="form-control" id="Tanggal" name="Tanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Tanggal = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Tanggal</div>
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                    </div>

                                    <p></p>
                                    <button className='btn btn-default' type='button' onClick={(e) => this.modalCariSarana()} >Tambah Buku</button>
                                    <p></p>
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Kode</th>
                                                    <th>Nama</th>
                                                    <th>Qty</th>
                                                    <th>Note</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DetailBuku.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <input type='hidden' name="BukuID[]" value={tr.BukuID} readOnly />
                                                                <input type="text" name="Kode[]" className='form-control form-control-sm' value={tr.Kode} readOnly />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="NamaBuku[]" className='form-control form-control-sm' value={tr.NamaBuku} readOnly />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="Qty[]" className='form-control form-control-sm' value={tr.Qty} onChange={(e) => {
                                                                    let data = this.state.DetailBuku;
                                                                    data[i].Qty = e.target.value;
                                                                    this.setState({ DetailBuku: data });
                                                                }} required />
                                                            </td>
                                                            <td>
                                                                <input type="text" name="Notes[]" className='form-control form-control-sm' value={tr.Notes} onChange={(e) => {
                                                                    let data = this.state.DetailBuku;
                                                                    data[i].Notes = e.target.value;
                                                                    this.setState({ DetailBuku: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <button className='btn btn-danger' type='button' onClick={(e) => {
                                                                    let data = this.state.DetailBuku;
                                                                    let temp = [];
                                                                    for (let dd in data) if (dd != i) temp.push(data[dd]);
                                                                    this.setState({ DetailBuku: temp });
                                                                }}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >

                <div className="modal fade" id="modalSarana" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Master Buku</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="search" className="form-control" onChange={(e) => this.handleCariBuku(e.target.value)} />
                                <p></p>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>Kode</th>
                                                <th>Judul</th>
                                                <th>Jumlah</th>
                                                <th>Keterangan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataSarana.map((tr, i) => {
                                                    return (<tr key={i} className='cursor' onClick={(e) => {
                                                        let data = this.state.DetailBuku;
                                                        data.push({ Kode: tr.Kode, NamaBuku: tr.Judul, Qty: 1, BukuID: tr.ID, Notes: "" });
                                                        this.setState({ DetailBuku: data });
                                                        document.getElementById('btnTutupModalSarana').click();
                                                    }}>
                                                        <td>{tr.Kode}</td>
                                                        <td>{tr.Judul}</td>
                                                        <td>{tr.Qty}</td>
                                                        <td>{tr.Keterangan}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalSarana'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus penghapusan buku" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Penghapusan Buku</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Main;
