import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataKpi: [],
            DataGuru: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/humas_api", { act: "data master wali murid", q: this.state.q }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/humas_api", { act: "detail master wali murid", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DataKpi: sql.kpi });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handlePilihImage(e, obj, compress) {
        let File = await openFile(e, compress);
        let Gambar = File[0].img;
        let Detail = this.state.Detail;
        Detail[obj] = Gambar;
        this.setState({ Detail: Detail });
    }

    handleModalCariGuru() {
        this.handleCariGuru("");
        openModal("modalCariGuru");
    }

    async handleCariGuru(val) {
        let sql = await api("controler/humas_api", { act: "data master guru", q: val }, true);
        if (sql.status == "sukses") this.setState({ DataGuru: sql.data });
    }

    handlePilihMurid(e) {
        let data = e.data;
        let Detail = this.state.Detail;
        Detail.IDUser = data.ID;
        Detail.Nama = data.Nama;
        Detail.NISN = data.NIP;
        this.setState({ Detail: Detail });
        document.getElementById('btnTutupModalCariGuru').click();
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Master Wali Murid</div>
                            <div className="col-md-3 d-flex justify-content-end align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/humas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah master wali murid" : "edit master wali murid"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Master Wali Murid" : "Edit Master Wali Murid"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>NIP</label>
                                                <input type="number" className='form-control' id="edtNIK" name="NIK" value={this.state.Detail.NIK || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIP</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control' id="edtNama" name="Nama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Nama = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control' id="TempatLahir" name="TempatLahir" value={this.state.Detail.TempatLahir || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TempatLahir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tempat Lahir</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Lahir</label>
                                                <input type="date" className='form-control' id="edtTanggalLahir" name="TanggalLahir" value={this.state.Detail.TanggalLahir || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalLahir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Lahir</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Pendidikan</label>
                                                <input type="text" className='form-control' id="Pendidikan" name="Pendidikan" value={this.state.Detail.Pendidikan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Pendidikan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Pendidikan</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Pekerjaan</label>
                                                <input type="text" className='form-control' id="Pekerjaan" name="Pekerjaan" value={this.state.Detail.Pekerjaan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Pekerjaan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Pekerjaan</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type="text" className='form-control' id="Email" name="Email" value={this.state.Detail.Email || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Email = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Email</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type="text" className='form-control' id="Telp" name="Telp" value={this.state.Detail.Telp || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Telp = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Telp</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' id="Alamat" name="Alamat" value={this.state.Detail.Alamat || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Alamat = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan Alamat</div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariGuru" tabIndex="-1" aria-labelledby="modalCariGuru" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content shadow">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Guru</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables
                                    tbody={this.state.DataGuru}
                                    thead={[
                                        { cap: "NIP", sort: "NIP", type: "str" },
                                        { cap: "NIK", sort: "NIK", type: "str" },
                                        { cap: "Nama", sort: "Nama", type: "str" },
                                        { cap: "Telp", sort: "Telp", type: "str" },
                                        { cap: "Email", sort: "Email", type: "str" },
                                        { cap: "Alamat", sort: "Alamat", type: "str" },
                                    ]}
                                    event={{ click: (e) => this.handlePilihMurid(e) }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariGuru'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/humas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master wali murid" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Wali Murid</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Main;
