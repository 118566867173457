import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, exportData, importData } from '../Modul';
import RendTables from '../component/RendTable';
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            DataGroup: [],
            Detail: {},
            DataMapel: [],
            FieldImport: [
                { cap: "PEGID", sort: "PEGID", type: "str" },
                { cap: "NoSK", sort: "NoSK", type: "str" },
                { cap: "NIK", sort: "NIK", type: "str" },
                { cap: "Nama", sort: "Nama", type: "str" },
                { cap: "TempatLahir", sort: "TempatLahir", type: "str" },
                { cap: "TanggalLahir", sort: "TanggalLahir", type: "str" },
                { cap: "TanggalMasuk", sort: "TanggalMasuk", type: "str" },
                { cap: "Email", sort: "Email", type: "str" },
                { cap: "Telp", sort: "Telp", type: "str" },
                { cap: "Alamat", sort: "Alamat", type: "str" },
                { cap: "StatusKepegawaian", sort: "StatusKepegawaian", type: "str" },
                { cap: "StatusPernikahan", sort: "StatusPernikahan", type: "str" },
                { cap: "JenisKelamin", sort: "JenisKelamin", type: "str" },
                { cap: "Pendidikan", sort: "Pendidikan", type: "str" },
                { cap: "Bank", sort: "Bank", type: "str" },
                { cap: "NoRek", sort: "NoRek", type: "str" },
                { cap: "Status", sort: "Status", type: "str" },
            ],
            DataImport: [],
            q: "",
            Akses: true
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/masterguru_api", { act: "data master guru", q: this.state.q }, true);
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/masterguru_api", { act: "detail master guru", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DataMapel: sql.mapel });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handleImEx(ACT, Type) {
        if (ACT == "Export") {
            let data = [], title = "";
            if (Type == "contoh") {
                data = [{
                    "PEGID": "20250001",
                    "NoSK": "00882112",
                    "NIK": "202500001",
                    "Nama": "Malihatul Hasanah",
                    "TanggalLahir": "2000-02-04",
                    "TempatLahir": "Sidoarjo",
                    "JenisKelamin": "Perempuan",
                    "StatusPernikahan": "Belum Menikah",
                    "Alamat": "Kediri",
                    "Telp": "081234779772",
                    "Email": "Lichaa04@gmail.com",
                    "Pwd": "20000204",
                    "Pendidikan": "SMA",
                    "StatusKepegawaian": "Tetap",
                    "NoRek": "889988",
                    "Bank": "BCA",
                    "TanggalMasuk": "2024-01-01",
                    "Status": "Aktif"
                }];
                title = "Contoh Format Import Guru";
            } else {
                let sql = await api("controler/masterguru_api", { act: "data master guru", q: "" }, true);
                if (sql.status == "sukses") data = sql.data;
                title = "Data Buku";
            }
            exportData(data, title, this.state.FieldImport);
        } else {
            this.setState({ DataImport: [] });
            openModal("modalImport");
        }
    }

    async handleLoadExcel(e) {
        let Data = await importData(e.target.files[0]);
        for (let dd in Data) Data[dd].IsSukses = "";
        this.setState({ DataImport: Data });
    }

    async handleProsesImport(e) {
        e.target.disabled = true;
        let Data = this.state.DataImport;
        let ct = 0;
        for (let dd of Data) {
            let sql = await api("model/masterguru_crud", {
                act: "tambah master guru",
                NIP: dd.PEGID,
                NIK: dd.NIK,
                Nama: dd.Nama,
                TanggalLahir: dd.TanggalLahir,
                TempatLahir: dd.TempatLahir,
                Alamat: dd.Alamat,
                Telp: dd.Telp,
                Email: dd.Email,
                Pwd: dd.TanggalLahir.replaceAll("-", ""),
                Pendidikan: dd.Pendidikan,
                JenisKelamin: dd.JenisKelamin,
                StatusKepegawaian: dd.StatusKepegawaian,
                NoSK: dd.NoSK,
                NoRek: dd.NoRek,
                Bank: dd.Bank,
                TanggalMasuk: dd.TanggalMasuk,
                IDPelajaran: "0",
                IsSertifikasi: "0",
                StatusPernikahan: dd.StatusPernikahan,
                Foto: "",
                Status: dd.Status,
            }, true);
            Data[ct].IsSukses = sql.status == "sukses" ? "bg-success text-light" : "bg-danger text-light";
            this.setState({ DataImport: Data });
            ct++;
        }
        e.target.disabled = false;
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-8 main-title">Master Guru</div>
                                    <div className="col-md-4 d-flex gap-1">
                                        <div className="input-group">
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleImEx('Import')}><i className="fas fa-upload"></i></button>
                                            <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Guru' />
                                            <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <RendTables
                                    tbody={this.state.Data}
                                    thead={this.state.Field}

                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                <div className="modal fade" id="modalImport" tabIndex="-1" aria-labelledby="modalImport" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Import Data</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm">
                                    <input type="file" className="form-control form-control-sm" id="edtFileExcel" accept=".xls, .xlsx" onChange={(e) => this.handleLoadExcel(e)} />
                                    <button type='button' className='btn btn-sm btn-default' onClick={(e) => this.handleImEx("Export", 'contoh')}>Contoh Format</button>
                                </div>
                                <p></p>
                                <div className="table-responsive" style={{ height: "700px", maxHeight: "700px", overflowY: "auto" }}>
                                    <table className="table table-stripped table-sm">
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.FieldImport.map((th, i) => {
                                                        return <th key={i}>{th.cap}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataImport.map((rr, i) => {
                                                    return <tr key={i} className={rr.IsSukses}>
                                                        {
                                                            this.state.FieldImport.map((dd, ii) => {
                                                                return <td key={ii}>{rr[dd.sort]}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-default" onClick={(e) => this.handleProsesImport(e, "mapel")}><i className='fas fa-save'></i> Proses</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterguru_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master guru" : "edit master guru"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Guru" : "Edit Guru"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>PEG ID</label>
                                                <input type="text" className='form-control' id="edtNIP" name="NIP" value={this.state.Detail.NIP || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIP = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIP</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>No. SK</label>
                                                <input type="text" className="form-control" name='NoSK' id="NoSK" value={this.state.Detail.NoSK || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NoSK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className="invalid-feedback">Silahkan Masukan No SK</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control' name="Nama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Nama = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan nama</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>NIK</label>
                                                <input type="text" className='form-control' id="edtNIK" name="NIK" value={this.state.Detail.NIK || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control' name="TempatLahir" value={this.state.Detail.TempatLahir || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TempatLahir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan tempat lahir</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Lahir</label>
                                                <input type="date" className='form-control' name="TanggalLahir" value={this.state.Detail.TanggalLahir || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalLahir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan tanggal lahir</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type="email" className='form-control' name="Email" value={this.state.Detail.Email || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Email = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan email</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type="text" className='form-control' name="Telp" value={this.state.Detail.Telp || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Telp = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan nomor telepon</div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.ID == "" &&
                                        <div className='form-group'>
                                            <label>Password</label>
                                            <input type="password" className='form-control' name="Pwd" value={this.state.DetailMurid.Pwd || ""} onChange={(e) => {
                                                let data = this.state.DetailMurid;
                                                data.Pwd = e.target.value;
                                                this.setState({ DetailMurid: data });
                                            }} />
                                            <span className='text-danger'>* Password bawaan adalah tanggal lahir Ex: 05122024</span>
                                        </div>
                                    }

                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' name="Alamat" value={this.state.Detail.Alamat || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Alamat = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan alamat</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Status Kepegawaian</label>
                                                <select className='form-select' name="StatusKepegawaian" id="edtStatusKepegawaian" value={this.state.Detail.StatusKepegawaian || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.StatusKepegawaian = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="GTT">Guru Tidak Tetap</option>
                                                    <option value="GTY">Guru Tetap Yayasan</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih status</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Masuk</label>
                                                <input type='date' className='form-control' name="TanggalMasuk" id="edtTanggalMasuk" value={this.state.Detail.TanggalMasuk || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalMasuk = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan status</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Jenis Kelamin</label>
                                                <select className="form-select" name='JenisKelamin' id="JenisKelamin" value={this.state.Detail.JenisKelamin || "Laki-laki"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JenisKelamin = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="Laki-laki">Laki-laki</option>
                                                    <option value="Perempuan">Perempuan</option>
                                                </select>
                                                <div className="invalid-feedback">Silahkan Masukan Jenis Kelamin</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Status</label>
                                                <select className='form-select' name="Status" value={this.state.Detail.Status || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Status = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="Aktif">Aktif</option>
                                                    <option value="Keluar">Tidak Aktif</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan status</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Pendidikan</label>
                                                <select className='form-select' name="Pendidikan" id="edtPendidikan" value={this.state.Detail.Pendidikan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Pendidikan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="SMA">SMA</option>
                                                    <option value="Diploma">Diploma</option>
                                                    <option value="Sarjana">Sarjana</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Doctor">Doctor</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan status</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Guru Sertifikasi</label>
                                                <select className='form-select' name="IsSertifikasi" id="IsSertifikasi" value={this.state.Detail.IsSertifikasi || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IsSertifikasi = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="0">Belum</option>
                                                    <option value="1">Sudah</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih status sertifikasi</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>No. Rek</label>
                                                <input type="text" className='form-control' name="NoRek" value={this.state.Detail.NoRek || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NoRek = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan no rekening</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Bank</label>
                                                <input type="text" className='form-control' name="Bank" value={this.state.Detail.Bank || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Bank = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan nomor Bank</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Mata Pelajaran</label>
                                                <select className='form-select' name="IDPelajaran" id="IDPelajaran" value={this.state.Detail.IDPelajaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDPelajaran = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan Pilih Mapel</option>
                                                    {
                                                        this.state.DataMapel.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih status sertifikasi</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Status Pernikahan</label>
                                                <select className='form-select' name="StatusPernikahan" id="StatusPernikahan" value={this.state.Detail.StatusPernikahan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.StatusPernikahan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="Belum Menikah">Belum Menikah</option>
                                                    <option value="Menikah">Sudah Menikah</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih status Status Pernikahan</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterguru_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master guru" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Guru</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus guru ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
